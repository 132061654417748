.pdf-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  font-family: $regular-sansserif-font;
  font-size: 0.8rem;
  margin-bottom: 1.5rem;
  li {
    margin-bottom: $m-space;
  }
  a {
    display: inline-block;
    padding-top: 0.5rem;
    &:before {
      content: "";
      display: inline-block;
      width: 28px;
      height: 13px;
      background: url(../icons/pdf-link.svg) no-repeat scroll 0 0;
      background-size: contain;
      margin-right: 0.3em;
    }
  }
  &.lesson-plan-list {
    a {
      padding-top: 0;
    }
  }
}
