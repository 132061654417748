// Ads

#banners {
	border: 1px solid $light-grey-2;
	padding: $s-space;
	.banner {
		@include smquery {
			text-align: center;
		}
	}
	footer {
		text-align: center;
		margin-top: 15px;
		@extend .small-sansserif;
	}
	@include smquery {
		margin: $m-space 0 $m-space 0;
	}
}

// Wallpapers

.wallpaper-links {
	position: relative;
	.wallpaper-left {
		margin-top: 40px;
		position: absolute;
		left: -200px;
		width: 200px;
		height: 770px;
		z-index: 30;
	}
	.wallpaper-right {
		margin-top: 40px;
		position: absolute;
		right: -200px;
		width: 200px;
		height: 770px;
		z-index: 30;
	}
}
.wallpapers {
		margin-top: 40px;
	    position: absolute;
	    width: 1600px;
	    height: 770px;
	    left: 50%;
	    transform: translateX(-50%);
}
@media (max-width: 1280px) {
	.wallpaper {
		overflow-x: auto;
	}
	.wallpaper-links, .wallpapers {
		display: none;
	}
}

// Top Banners

#top-banners {
	background-color: $lightgrey;
	text-align: center;
	padding: 25px 0px;

	.ad {
		margin: rem-calc(25) 0;
		display: inline-block;
		vertical-align: top;
	}
	.leader-board {
		margin-left: -5px;
		padding: 0 7.5px 0 0;
		@include mdquery {
			margin-left: inherit;
		}
		@include smquery {
			display: none;
		}
	}
	.mini-banner {
		margin-left: -5px;
		padding: 0 0 0 7.5px;
		@include mdquery {
			display: none;
		}
	}
	.small-12 {

		display: flex;
    	justify-content: center;
		gap: 6px;

		@include mdquery {
			padding: 0 rem-calc(10);
		}
	}
	.mobile-header {
		display: none;
		@include smquery {
			display: block;
		}
	}
}


.support-ad {
	border: 1px solid $light-grey-2;
	padding: 20px 20px $s-space;
	display: block;
	margin: 0 auto $l-space;
	box-sizing: content-box;

	&.medium-rectangle { 
		max-width: 300px;
	}
	&.wide-skyscraper { 
		max-width: 160px;
		@include mdquery {
			display: none;
		}
	}
	&.small-skyscraper { 
		max-width: 120px;
		@include mdquery {
			display: none;
		}
	}
	&.full-banner { 
		max-width: 468px;
	}

	.ad-label {
		font-family: $light-sansserif-font;
		font-size: 0.6rem;
		color: $mediumlightgrey;
		text-align: center;
		margin: $s-space 0 0;
	}

	@include smquery {
		border: none;
		padding: 0;
	}
}


@media (max-width: 1075px) {
	#top-banners {
		.mini-banner {
			display: none;
		}
	}
}
