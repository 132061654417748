.showing {
	font-family: $regular-sansserif-font;
	margin-bottom: rem-calc(50);
	@include smquery {
		margin-bottom: $m-space;
	}
}
#ch-app-post {
	.showing {
		margin-bottom: rem-calc(50);
		@include smquery {
			margin-bottom: $m-space;
		}
	}
}