nav {
	@include smquery {
		text-align: right;
	}
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;

		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-wrap: wrap;
		
		li {
			display: inline-block;
			a:not(.btn) {
				margin: 0 rem-calc(10);
				color: $darkestgrey;
				font-family: $regular-sansserif-font;
				@extend .small-sansserif;
				&:hover, &:focus {
					text-decoration: none;
					box-shadow: 0 2px 0 $red;
				}
			}
			a {
				margin-top: 5px;
				&.btn,
				&.button {
					display: inline-block;
					margin-left: rem-calc(10);
				}
				
			}
		}
		@include smquery {
			display: none;
		}
	}
	#mobile-menu-toggle {
		float: right;
		margin-bottom: 0;
		text-transform: uppercase;
		padding: $s-space 10px;
		margin-left: 10px;
		@extend .dark;
		@extend .small-sansserif;
	}
}
@media (max-width: 1024px) {
	.utility-nav {
		ul {
			li {
				a {
					font-size: rem-calc(11) !important;
					margin-left: 5px !important;
					margin-right: 5px !important;
				}
			}
		}
	} 
}