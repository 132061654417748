#main-header {
	.utility {
		padding-top: rem-calc(44);
		padding-bottom: rem-calc(44);
		@include smquery {
			padding-top: $s-space;
			padding-bottom: $s-space;
			border-bottom: 10px solid $darkgrey;
		}
		&-nav {
			display: flex;
			align-items: flex-end;
			flex-direction: column;
			flex: 1 auto;
			
		}
		.lang-toggle {
			margin-bottom: 15px;
			text-align: right;
			a {
				color: $darkestgrey;
				font-family: $regular-sansserif-font;
				@extend .small-sansserif;
				&:hover, &:focus {
					text-decoration: none;
					box-shadow: 0 2px 0 $red;
				}
			}
			
			@include smquery {
				display: none;
			}

			@media (max-width: 1024px) {
				a {
					font-size: rem-calc(11) !important;
				}
			}
		}
		.header-cta {
			padding: $s-space 10px;
			margin-bottom: 0;
			display: none;
			@extend .small-sansserif-bold;
			@include smquery {
				display: inline-block;
			}
		}
		.brand {
			h1 {
				margin: 0;
				line-height: 0;
			}
			a {
				@include smquery {
					top: 0;
					max-width: 135px;
					img {
						width: 119px;	
					}
				}
			}
		}
		@import "utility-nav";

	}
	.primary-nav {
		background-color: $darkgrey;
		@import "primary-nav";
		@include smquery {
			display: none;
		}
	} 
	// Mobile menu view state
	.mobile-menu {
		display: none;
		@include mdquery {
			display: block;
		}

		&-cta {
			border-top: 1px solid rgba( $white, .3 );
			border-bottom: 1px solid rgba( $white, .3 );
			padding: 20px 0;

			.btn {
				margin-right: 10px;
			}
		}
	} 
	@import "mobile-menu";
	// Menu Colors
	.primarycolours {
		.first-level {
			color: $white;
			&:hover, &:focus {
				color: #b4b4b4;
			}
			&.hasActiveChild {
				box-shadow: inset 0px -8px 0px -5px $red;
			}
		}
		.second-level-menu { 
			background-color: $white;
			font-size: rem-calc(18);
			li.selected {
				> a {
					span {
						box-shadow: 0 2px 0 $red;
					}
				}
			}
			li.isActive {
				background-color: #f3f3f3;
			}
			.second-level {
				color: $darkgrey;
				&:hover, &:focus {
					span {
						box-shadow: 0 2px 0 $red;
					}
				}
				span {
					transition: box-shadow .25s;
					line-height: 1.3;					
				}
			}
			.third-level-menu {
				background-color: #f3f3f3;
				.third-level {
					color: $darkgrey;
					&:hover, &:focus {
						span {
							box-shadow: 0 2px 0 $red;
						}
					}
					span {
						transition: box-shadow .25s;
						line-height: 1.3;					
					}
				}
			}
		}
	}
}

@include fronly {
	#main-header {
		.brand {
			img {
				top: 10px;
				position: relative;
			}
		}
	}
}