// Blockquotes

blockquote {
	border-left: 3px solid $red;
	display: inline-block;
	padding: 0 rem-calc(80) 0 $m-space;;
	position: relative;
	margin-bottom: rem-calc(30);
	font-style: italic;
	cite {
		margin-top: $cite-margin-top;
		color: $cite-colour;
		font-style: normal;
		font-size: rem-calc(16);
	}
	p {
		margin-bottom: 0 !important;
	}
	&:before {
		
	}
	.notItalic {
		font-style: normal;
		font-size: rem-calc(28);
	}
}
blockquote, blockquote p {
	font-family: $blockquote-font;
	color: $blockquote-colour;
	font-size: $blockquote-font-size;
	line-height: 1.3;
}
@include ffonly {
	blockquote {
		.notItalic {
			font-size: rem-calc(34);
		}
	}
}