.feature-item {
	margin-bottom: $l-space;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.thumb, .content {
		width: 50%;
	}
	.thumb a {
		display: block;
		box-shadow: none;
		background: none;
	}

	.content {
		text-align: center;
		padding: 0 20px;
		.meta {
			.type {
				font-size: rem-calc(12);
				font-family: $heading-font;
				color: $mediumgrey;
			}
		}
	}
	h2.h1 {
		font-size: $h1-font-size;
		line-height: 1.2;
		text-transform: none;
		&:after {
			content: none;
		}
	}

	@include breakpoint(medium down) { 
		display: block;
		text-align: center;
		.thumb, .content {
			width: 100%;
		}
		.thumb {
			margin-bottom: $m-space;
		}
	}
}