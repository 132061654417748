.pagination {
	font-family: $regular-sansserif-font;
	text-align: center;
	margin-top: $m-space;
	padding: 6px 13px;
	@include smquery {
		margin-top: $s-space;
		margin-bottom: 0;
	}
	li {
		vertical-align: top;
		a, button {
			border: 1px solid $mediumgrey;
			border-radius: 100%;
			color: $mediumgrey;
			width: 30px;
			height: 30px;
			padding: 4px 0 0 0;
			margin: 0 2.5px;
			box-shadow: none;
			&:hover {
				background-color: $darkgrey;
				color: $white;
			}
			&.disabled {
				border-color: $light-grey-2;
				color: $light-grey-2;
				pointer-events: none;
			}
		}
		&.active a {
			background-color: $darkgrey;
			color: $white; 
		}
	}
	.first-page {
		a, button {
			&:before {
				margin: 0;
				content: '\00AB';
			}
		}
	}
	.last-page {
		a, button {
			&:before {
				margin: 0;
				content: '\00BB';
			}
		}
	}
	.pagination-previous {
		a, button {
			&:before {
				margin: 0;
				content: '\2039';
				position: relative;
				top: -1px
			}
			&.disabled {
				&:before {
					top: 0;
				}
			}
		}
	}
	.pagination-next {
		a, button {
			&:after {
				margin: 0;
				content: '\203A';
				position: relative;
				top: -1px;
			}
		}
	}
	&--awards {
    display: flex;
    justify-content: center;
	}
}
@include ffonly {
	.pagination { 
		li {
			a {
				padding: 2px 0 0 0;
				@include mdquery {
					padding: 5px 0 0 0;
				}
			}
		}
	}
}

@media (max-width: 1026px) {
	.pagination {
		li {
			a {
				&:hover {
					background-color: $white;
					color: $darkgrey;
				}
			}
		}
	}
}