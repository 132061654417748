.sponsoredbytitle {
	font-family: $medium-sansserif-font;
	margin: $m-space 0 10px 0 !important;
	@extend .h5;
}
.sponsor-information {
	border-top: 2px solid $light-grey-4;
	border-bottom: 2px solid $light-grey-4;
	padding: $m-space 0;
	@include mdquery {
		margin-bottom: $l-space;
	}
	.image {
		margin-bottom: 0;
	}
	.title {
		h2 {
			margin: -10px 0 $s-space 0;
			@extend .h3;
			@include smquery {
				margin-top: $s-space;
			}
		}
	}
	.description {
		@extend .small-sansserif;
		p:last-of-type {
			margin-bottom: 0;
		}
	}
}