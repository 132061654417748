// Kids - Young citizen - Details
.kids {
	&-detail {
		&.body {
			.image {
					float: left;
					margin: $xs-space $m-space $m-space 0;
					@include smquery {
						float: none;
						margin: $m-space 0;
					}

					.img-container {
						display: table;
						img {
							max-width: 335px;
							@include smquery {
								max-width: 100%;
							}
						}
						.image-caption {
							display: table-caption;
							caption-side: bottom;
							@extend .small-sansserif;
							margin: $s-space 0 0 0;
							font-family: $medium-sansserif-font;
						}
					}
			}
			.project-info {
				font-family: $light-sansserif-font;
				font-size: 0.8rem;
				line-height: 1.2;
				.title {
					font-size: $h3-font-size;
					font-family: $heading-font;
					line-height: 1.3;
				}
      }
      .question {
        font-weight: bold;
        margin-bottom: $xs-space;
      }
		}
	}
}