// Years filters

#awards-recipients-years {
	.radio {
		display: inline-block;
		width: 50%;
		@include smquery {
			width: 100%;
		}
	}
	@include mdquery {
		margin-bottom: $m-space;
	}
}