// Share Icons

.share-icons {
	list-style-type: none;
	margin: 0;
	padding-left: 0;
	@include smquery {
		text-align: left;
		margin: 0 0 $s-space 0;
	}
	li {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 0;
		a {
		    width: 30px;
			height: 30px;
			//border: 1px solid $light-grey-2;
		    box-shadow: 0 0 1px 0px $light-grey-2 inset, 0 0 1px 0px $light-grey-2;
			border-radius: 100%;
			color: $mediumgrey;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 2.5px;
			@extend .small-sansserif;
			svg {
				width: 17px;
				height: 17px;
			}
			&:hover, &:focus {
				background-color: $darkgrey;
				color: $white;
    			//border-color: $darkgrey;
    			box-shadow: 0 0 1px 0px $darkgrey inset, 0 0 1px 0px $darkgrey;
				svg path {
					fill: white;
				}
			}
		}

	}
}