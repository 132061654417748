// Submenu open state

.open {
  display: block;
  opacity: 1;
  clip: rect(-11px, 800px, 1300px, 0);
  margin-top: -4px;
}

nav {
  a,
  button {
    font-family: $light-sansserif-font;
    padding: rem-calc(15);
    display: block;
    line-height: inherit;
  }
  > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    > li {
      display: inline-block;
      vertical-align: top;
      &:first-of-type {
        > a,
        > button {
          padding-left: 0;
        }
      }
      > a,
      > button {
      }
      &.children {
        > a,
        > button {
          &:after {
            content: "";
            @extend %icon;
            @extend %icon-chevron-down-white;
            margin-left: 10px;
            width: 7px;
            height: 7px;
            display: inline-block;
            vertical-align: middle;
          }
          &:hover,
          &:focus {
            &:after {
              @extend %icon-chevron-down-grey;
            }
          }
        }
      }
      .first-level {
        &:hover ~ ul {
          @extend .open;
          @include smquery {
            position: relative;
          }
        }
        /*&:focus + ul {
					@extend .open;
				}*/
      }
      // Children
      ul.second-level-menu {
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: 9000;
        width: 320px;
        clip: rect(0, 700px, 0, 0);
        margin: -10px;
        webkit-back-face-visibility: hidden;
        overflow: visible;
        border: 1px solid $light-grey-2;
        @include transform-style(preserve-3d);
        @include transform(translate3d(0, 0, 0));
        @include transition(all 0.22s linear 0.4s);
        // Second Level Menu UL
        @include smquery {
          margin-left: -10px;
          margin-top: 0;
          margin-bottom: 0;
          width: calc(100% + 20px);
          position: relative;
          max-height: 0;
        }
        &:hover,
        &.open {
          @extend .open;
        }

        > li {
          display: block;
          border-top: 1px solid $white;
          border-bottom: 1px solid $white;
          @include transition(all 0.22s linear 0.4s);
          &:first-of-type {
            padding-top: 15px;
            &.isActive {
              border-top: 1px solid #f3f3f3;
              &:before {
                border-bottom: 10px solid #f3f3f3;
              }
            }
            &:before {
              position: absolute;
              width: 0;
              height: 0;
              content: "";
              z-index: 500;
              border-bottom: 10px solid $white;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              left: 20px;
              top: -10px;
              transition: border-bottom 0.25s linear 0.4s;
            }
          }
          &:last-of-type {
            padding-bottom: 15px;
            &.isActive {
              border-bottom: 1px solid #f3f3f3;
            }
          }
          &.children {
            .second-level {
              width: calc(100% - 50px);
              vertical-align: middle;
            }
          }
          &.isActive {
            border-top: 1px solid $light-grey-2;
            border-bottom: 1px solid $light-grey-2;
            .third-level-toggle {
              &:after {
                height: 2px;
                @include background-size(inherit);
                @extend %icon-minus-darkgrey;
              }
              &:hover {
                &:after {
                  @extend %icon-minus-red;
                }
              }
            }
          }
          .second-level {
            display: inline-block;
            line-height: 1;
            padding: rem-calc(15px);
            padding-bottom: 15px;
          }
          .third-level-toggle {
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            &:after {
              content: "";
              display: block;
              width: 15px;
              height: 15px;
              @extend %icon-plus-darkgrey;
              @include background-size(100%);
            }
            &:hover {
              &:after {
                @extend %icon-plus-red;
              }
            }
          }
          // Third level Children
          ul.third-level-menu {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            z-index: 6000;
            clip: rect(0, 700px, 0, 0);
            margin: 0px;
            width: 100%;
            webkit-back-face-visibility: hidden;
            @include transform-style(preserve-3d);
            @include transform(translate3d(0, 0, 0));
            @include transition(all 0.22s linear 0.4s);
            > li {
              .third-level {
                padding-left: $m-space;
                font-size: rem-calc(16);
                padding-top: 10px;
                padding-bottom: 10px;
              }
            }
            &.open {
              @extend .open;
              max-height: 600px;
            }
          }
        }
      }
    }
  }
  #search-control {
    float: right;
    a {
      padding-right: 0;
      color: $white;
      &:focus,
      &:hover {
        .search-open {
          background-color: $red;
          padding: 0 8px;
          border-radius: 5px;
        }
      }
    }
    &.active {
      .search-open {
        display: none;
      }
      .search-close {
        display: block;
      }
    }
    .search-open svg,
    .search-close svg {
      display: inline-block;
      vertical-align: middle;
    }
    .search-open {
      display: block;
      padding: 0 8px;
      svg {
        width: 20px;
        margin-top: -4px;
      }
    }
    .search-close {
      display: none;
      background-color: $red;
      margin-top: -20px;
      bottom: -20px;
      position: relative;
      padding: 0 8px 15px 8px;
      border-radius: 5px 5px 0 0;
      svg {
        width: 20px;
      }
    }
  }
}

// Custom Meni Item Classes

.ggawards {
  > a {
    &:before {
      content: "";
      float: left;
      display: block;
      margin: 3px 10px 0 0;
      @extend %icon;
      @extend %icon-ggawards;
    }
  }
}
