.appearance-none {
  appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  -moz-appearance: none;
}

#filters {
  max-width: 335px;
  @include mdquery {
    max-width: 100%;
    margin-bottom: $m-space;
  }
  .filter-block {
    @extend .small-sansserif;
    color: $darkgrey;
    background-color: $lightgrey;
    border: 1px solid $light-grey-2;
    padding: $m-space;
    margin-bottom: $s-space;
    border-radius: $xs-space;
    fieldset {
      position: relative;
      border: none;
      padding: 0;
      margin: 0;
      &:hover {
        .toggle {
          &:hover {
            &:before {
              @extend .icon-o-minus-dark;
            }
          }
        }
      }
      @include mdquery {
        height: 20px;
        overflow: hidden;
      }
      &.collapsed {
        @include mdquery {
          height: inherit;
          overflow: auto;
        }
        legend {
          &:hover {
            .toggle {
              &:before {
                @extend .icon-o-plus-dark;
                @include mdquery {
                  background-image: url("../icons/filter-minimize-light.svg") !important;
                }
              }
            }
          }
          .toggle {
            &:before {
              @include mdquery {
                background-image: url("../icons/filter-minimize-light.svg") !important;
              }
            }
          }
        }
        div.app-search-input {
          display: none;
          @include mdquery {
            display: block !important;
          }
        }
        div.radio {
          display: none !important;
          @include mdquery {
            display: block !important;
          }
          label {
            &:before {
              @include mdquery {
                margin-left: -8px;
              }
            }
          }
        }
        div.tag {
          display: none !important;
          @include mdquery {
            display: inline-block !important;
          }
        }
        div.checkbox {
          display: none !important;
          @include mdquery {
            display: inline-block !important;
          }
        }
        .select {
          display: none;
          @include mdquery {
            display: block !important;
          }
        }
        .toggle {
          &:before {
            @extend .icon-o-plus-light;
          }
        }
        .reset {
          display: none;
          @include mdquery {
            display: block !important;
          }
        }
      }
      div:first-of-type {
        margin-top: $s-space;
      }
    }
    legend {
      background-color: transparent;
      text-transform: uppercase;
      padding: 0;
      margin-bottom: 0px;
      display: block;
      width: 100%;
      cursor: pointer;
      margin-left: 0;
      @extend .small-sansserif-bold;
      &:before {
        content: "";
        @extend %icon;
        display: inline-block;
        vertical-align: middle;
        margin: -2px 10px 0 0;
      }
      &:hover {
        .toggle {
          &:before {
            @extend .icon-o-minus-dark;
            @include mdquery {
              background-image: url("../icons/filter-expand-light.svg") !important;
            }
          }
        }
      }
      .toggle {
        float: right;
        display: inline-block;
        color: $light-grey-2;
        &:before {
          content: "";
          display: block;
          width: 15px;
          height: 15px;
          @extend %icon;
          @extend .icon-o-minus-light;
          @include mdquery {
            background-image: url("../icons/filter-expand-light.svg") !important;
          }
        }
      }
    }
    .select {
      margin-top: $s-space;
    }
    .radio {
      label {
        &:before {
          margin-left: -12px;
        }
      }
    }
    .reset {
      display: block;
      margin-top: $s-space;
      a {
        @extend .removeGenericLink;
        @extend .small-sansserif;
        color: $darkgrey;
        text-decoration: underline;
      }
    }
  }
}

// Filter group icons

#awards-recipients-years {
  legend:before {
    @extend %icon-filter-year;
    width: 13px;
    height: 13px;
  }
}
#awards-recipients-categories {
  legend:before {
    @extend %icon-filter-category;
    width: 13px;
    height: 13px;
  }
}
legend {
  &.filter-format:before {
    @extend %icon-filter-format;
    width: 13px;
    height: 13px;
  }
  &.filter-themes:before {
    @extend %icon-filter-theme;
    width: 13px;
    height: 13px;
  }
  &.filter-era:before {
    @extend %icon-filter-era;
    width: 13px;
    height: 13px;
  }
  &.filter-geography:before {
    @extend %icon-filter-geography;
    width: 13px;
    height: 13px;
  }
  &.filter-level:before {
    @extend %icon-filter-grade-level;
    width: 15px;
    height: 13px;
  }
  &.filter-search:before {
    @extend %icon-filter-search;
    width: 15px;
    height: 15px;
  }
  &.filter-province:before {
    @extend %icon-filter-province;
    width: 10px;
    height: 16px;
  }
  &.filter-year:before {
    @extend %icon-filter-year;
    width: 15px;
    height: 15px;
  }
}
