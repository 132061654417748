// Related Stories & Content List Grid View

.tile-item {
	padding-bottom: $m-space;
	h3 {
		margin: $s-space 0;
		@include smquery {
			margin: $s-space 0 $xs-space 0!important;	
		}
	}
	.thumb {
		position: relative;
		img {
			height: auto;
			// force smaller images to render at full-width:
			// we're choosing layout over image quality
			width: 100%; 
		}
	}
	.teaser {
		font-size: 1em;
		margin-bottom: 20px;
		p {
			margin-bottom: 0;
		}
	}
	&:nth-child(odd) {
		@include smquery {
			clear: left; 
		}
	}
	.meta {
		text-transform: uppercase;
		padding: 0;
		a {
			color: $mediumgrey;
		}
		font-size: rem-calc(12);
		font-family: $medium-sansserif-font;
		color: $mediumgrey;
		.type {
			font-family: $heading-font;
			text-transform: uppercase;
		}
	}
}

