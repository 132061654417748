.tag {
	display: inline-block;
	input[type="checkbox"] {
		@include appearance(none);
		clip: rect(0 0 0 0);
		position: absolute;
		left: -9999px;
		&:checked:not([disabled]) ~ label {
			background-color: $red;
			color: white;
		}
		&:disabled ~ label {
			background-color: $lightgrey;
			color: $light-grey-3;
			cursor: not-allowed;
			&:hover {
				border-color: $light-grey-2;
			}
		}
		&:focus ~ label {
			border-color: $red;
		}
	}
	label {
		padding: 1px 10px;
		border: 1px solid $light-grey-2;
		color: $darkgrey;
		background-color: white;
		border-radius: 5px;
		margin: 0 6px 6px -4px;
		transition: border .25s;
		@include smquery {
			margin-left: 0;
		}
		&:hover {
			border: 1px solid $red;
		}
	}
}	

// Firefox Fixes
 
@include ffonly {
	.tag {
		input[type="checkbox"] {
			margin-bottom: 0;
			clip: rect(0 0 0 0);
			margin-right: -12px;
		}
		label {
		}
	}
}
@include ieonly {
	.tag {
		input[type="checkbox"] {
			margin-bottom: 0;
			clip: rect(0 0 0 0);
			margin-right: -12px;
		}
		label {
		}
	}
}