.disruptive-cta {
  visibility: hidden;
  background-color: $white;
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: -100%;
  width: 768px;
  box-shadow: 0 0 60px $mediumlightgrey;
  z-index: 40;
  transition: right 0.25s;
  @include smquery {
    display: none !important;
  }
  &.hasImage {
    .content {
      position: absolute;
      top: 50%;
      @include transform("translateY(-50%)");
      width: calc(100% - 260px);
    }
  }
  &.active {
    visibility: visible;
    display: block;
    right: 0;
  }
  &.light {
    border-top: 1px solid $mediumlightgrey;
    border-left: 1px solid $mediumlightgrey;
    .inner {
      background-color: $lightgrey;
      border: 1px solid $light-grey-4;
    }
  }
  &.dark {
    border-top: 1px solid $mediumlightgrey;
    border-left: 1px solid $mediumlightgrey;
    .inner {
      background-color: $darkgrey;
      border: 1px solid $light-grey-4;
      color: $white;
    }
  }
  .inner {
    padding: $m-space;
    position: relative;
    .disruptive-cta-close-link {
      background-color: $red;
      padding: 5.5px;
      position: absolute;
      top: -10px;
      left: -10px;
      border-radius: 50%;
      border: 2px solid $red;
      transition: background-color 0.25s;
      &:before {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        @extend %icon;
        @extend %icon-close-white;
      }
      &:hover {
        background-color: $white;
        padding: 3.5px;
        &:before {
          background-image: url(../icons/mobile-expand-hover.svg);
          transform: rotate(45deg);
          width: 19px;
          height: 19px;
        }
      }
      &:focus {
        outline: 1px dotted;
      }
    }
    .image {
      display: inline-block;
      float: left;
      margin-right: rem-calc(50);
      padding-top: $xs-space;
    }
    .content {
      display: inline-block;
      .title {
        @extend .h3;
        line-height: 1.2;
        font-family: $heading-font;
      }
      .short-description {
        font-size: rem-calc(18);
        margin: 10px 0 20px 0;
        @extend .medium-sansserif;
        p {
          margin-bottom: 0;
        }
      }
      .link {
        a {
          margin-bottom: 0;
          text-transform: inherit;
          line-height: 1rem;
        }
      }
    }
  }
}

// Themes
