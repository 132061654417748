// Mobile Menu

html.menuOpen {
	overflow-y: hidden;
}
#mobile-menu-toggle {
	&:after {
		content: '';
		display: block;
		width: 22px;
		height: 13px;
		@extend %icon;
		@extend %icon-burger;
	}
}
.mobile-menu {
	background-color: $darkgrey;
	max-height: 0;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 600;
	.column {
		padding-left: 10px;
		padding-right: 10px;
	}
	&.open {
		max-height: 100%;
		height: 100%;
		overflow-y: scroll;
	}
	.close-mobile-menu {
		float: right;
		padding: 15px 5px;
		i {
			text-align: right;
			width: 20px;
			height: 20px;
			display: block;
		}
	}
	
	// Mobile Navs

	nav {
		padding-left: $s-space;
		padding-right: $s-space;

		// Global Link Styles

		ul li a:not(.btn), ul li button {
			line-height: 1;
			display: block;
			@extend .medium-sansserif;
		}

		// utility nav

		&.mobile-utility-nav {
			margin-top: 7px;;
			li {
				a {
					padding: 12px 0;
					font-size: rem-calc(16px);
					color: $light-grey-2 !important;
				}
			}
		}
		> ul {
			margin: 0 10px;
			> li {
				display: block;
				&.children {
					> a, > button {
						display: block;
						width: 100%;
						text-align: left;
						&:after {
							content: '';
							@extend %icon;
							@extend %icon-chevron-down-white;
							margin-left: $s-space;
							margin-top: $xs-space;
							width: 10px;
							height: 10px; 
							display: inline-block;
						}
					}
					&.isActive {
						> a, > button {
							&:after {
								content: '';
								@extend %icon-chevron-up-white;
							}
						}
					}
				}
				> a:not(.btn), > button {
					padding: 15px 0;
				}
				// Second Level
				.second-level-menu {
					list-style-type: none;
					padding: 0;
					margin: 0;
					display: none;
					margin-left: -$s-space;
					margin-right: -$s-space;
					&.open {
						display: block;
					}
					> li {
						border-bottom: 1px solid $light-grey-4;
						.second-level {
							padding: $s-space;
							display: inline-block;
							width: calc(100% - 50px);
							vertical-align: middle;
						}
						&.children {
							position: relative;
							.second-level { 
								display: inline-block;
							}
							.third-level-toggle {
								padding: $s-space;
								display: inline-block;
								vertical-align: middle;
								&:after {
									content: '';
									display: block;
									@extend %icon-plus-darkgrey;
									width: 9px;
									height: 10px;
									margin-top: 3px;
								}
								&.isActive {
									&:after {
										@extend %icon-minus-darkgrey;
										height: 2px;
										margin-top: 6px;
									}
								}
							}
							.third-level-menu { 
								padding: 0;
								list-style-type: none;
								display: none;
								&.open {
									display: block;
								}
								li {
									.third-level {
										padding: $s-space;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// Mobile Primary Nav 

	.mobile-primary-nav {
		padding-top: 0;
	}

	// Mobile Search Component

	.search-results {
		background-color: $lightgrey;
		margin-top: $s-space;
	}

	#mobile-search-control {
		padding: $s-space 0 0 0;
		overflow: hidden;
		label {
			color: $white;
			visibility: none;
			height: 0;
			overflow: hidden;
		}
		input[type="text"] {
			display: inline-block;
			vertical-align: top;
			border: none;
			width: 98%;
			height: 40px;
			padding: 10px;
			margin-bottom: 0;
			margin-top: $xs-space;
			@include border-radius(2px);
			@extend .medium-sansserif;
		}
		input[type="submit"] {
			padding: 0;
			text-indent: -9999px;
			background-color: transparent;
			background-repeat: no-repeat;
			background-size: 20px;
			position: relative;
			margin-top: -30px;
			margin-bottom: 0;
			left: -20px;
			width: 25px;
			height: 25px;
			float: right;
			border: 0;
			@include border-radius(0);
			@extend %icon-search-dark;
		}
		.predictiveSearchResults {
			position: relative;
			background-color: transparent;
			border: 0;
			max-height: 180px;
			overflow-y: auto;
			margin: $s-space 0;
			@extend .medium-sansserif;
			.predictiveSearchCategory {
				background-color: transparent;
				display: none;
			}
			.search-result {
				padding: 0;
				a {
					color: $darkgrey;
					padding: 10px 0;
					line-height: 1;
					display: block;
					font-size: rem-calc(16);
				}
			}
		}
	}

	// Mobile Utility Nav 

	.mobile-utility-nav {
		padding-bottom: $m-space;
		.btn {
			margin-bottom: 0;
			width: 100%;
		}
		ul {
			li {
				a {
					color: $white;
				}
			}
		}
	}

	// Mobile Menu CTA List
	.mobile-menu-cta-list {
		ul {
			li {
				display: inline-block;
			}
		}
	}
}

// Browser fixes

@include ffonly {
	#mobile-search-control {
		input[type="submit"] {
			margin-top: 10px;
		}
	}
}