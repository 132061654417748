.landing-page-intro {
	padding: 0 0 40px 0;
	text-align: center;
	h1 {

	}
	.intro {
		color: $mediumgrey;
		font-family: $regular-sansserif-font;
	}
}