#breadcrumbs {
	font-family: $heading-font;
	text-transform: uppercase;
	letter-spacing: .5px;
	font-size: rem-calc(12);
	padding-top: 4px;
	@include smquery {
		display: none;
	}
	a {
		color: $mediumgrey;
		transition: box-shadow .25s;
		&:hover, &:focus {
			box-shadow: 0 2px 0 $red;
		}
	}
	ul {
		li {

		}
	}
}