// Image With Caption

.image-with-caption {
	display: table;
	margin-bottom: $m-space;
	&.center {
		margin: 0 auto $m-space auto;
	}
	img {
		display: inline-block;
		@include smquery {
			max-width: 100%;
		}
	}
}
.photo-footer {
	display: table-caption;
	caption-side: bottom;
	.photo-caption {
		@extend .small-sansserif;
		margin: $s-space 0 10px 0;
		font-family: $medium-sansserif-font;
	}
	.photo-credit {
		@extend .xsmall-sansserif;
		text-transform: uppercase;
		font-family: $medium-sansserif-font;
	}
}

.photo-caption {
	@extend .small-sansserif;
	margin: $s-space 0 10px 0;
	font-family: $medium-sansserif-font;
}

.photo-credit {
	@extend .xsmall-sansserif;
	text-transform: uppercase;
	font-family: $medium-sansserif-font;
}