// Global Footer

#main-footer {
	font-family: $regular-sansserif-font;
	font-size: rem-calc(14);
	a:not(.button) {
		color: $white;
		font-family: $regular-sansserif-font;
		font-size: rem-calc(16);
		transition: box-shadow .25s;
		&:hover {
			box-shadow: 0 2px 0 $red;
		}
	}
	.cta {
		background-color: $darkgrey;
		color: $white;
		width: 100%;
		@include mdquery {
			padding: $m-space 0;
		}
		@include smquery {
			display: block;
		}
		.columns {
			@include smquery {
				display: block;
			}
		}
		.cta-left {
			img {
				position: relative;
				bottom: 0;
			}
			@include smquery {
				text-align: center;
				margin-bottom: $s-space;
			}
		}
		.cta-right {
			margin: $l-space 0;
			h2 {
				color: $white;
				font-size: $h2-font-size;
				margin-top: 0;
			}
			.button {
				text-transform: uppercase;
				margin-top: $m-space;
			}
			a:not(.button) {
				margin: 0 $xs-space;
			}
		}
	}
	.primary {
		background-color: $darkergrey;
		padding-top: rem-calc(50);
		padding-bottom: rem-calc(50);
		color: $mediumlightgrey;
		@include smquery {
			text-align: center;
		}
		.left-info {
			.socialmedia {
				ul {
					list-style-type: none;
					margin: 0 0 0 0;
					padding: 0;
					li {
						display: inline-block;
						margin: 0 $s-space;
						&:first-of-type {
							margin-left: 0;
						}
						a {
							transition: all .25s;
							max-width: 25px;
							display: inline-block;
							&:hover {
								box-shadow: none;
								opacity: .6;
							}
						}
					}
				}
			}
			.footer-info {
				a {
				    box-shadow: 0 1px 0 $red;
					transition: box-shadow .25s;
					&:before {
						display: block;
						content: '';
						margin: 40px 0 0 0;
					}
					&:after {
						display: block;
						content: '';
						margin: 0 0 15px 0;
					}
					&:hover {
						text-decoration: none;
						box-shadow: 0 2px 0 $red;
					}
					&:after {
						@extend %clearfix;
					}
				}
				p {
					margin-bottom: 0;
				}
			}
		}
		h5 {
			text-transform: uppercase;
			color: $mediumlightgrey;
			margin-top: $l-space;
			@extend .small-sansserif-bold;
		}
		.general {
			ul {
				list-style-type: none;
				padding: 0;
				margin: 0;
				@include columnCount(3);
				@include columnGap(30px);
				@include mdquery {
					@include columnCount(2);
					@include columnGap(inherit);
				}
				@include smquery {
					@include columnCount(1);
					margin-top: $s-space;
				}
				li {
					@include column-break-inside(avoid);
					page-break-inside: avoid;
					break-inside: avoid;
					display: block;
					padding-bottom: 10px;
				}
			}
		}
		.logos {
			margin-top: $l-space;
			@include mdquery {
				margin-top: $s-space;
			}
			ul {
				margin: 0;
				li {
					display: inline-block;
					vertical-align: bottom;
					margin-right: 60px;
					@include mdquery {
						margin: 10px;
						max-width: 130px;
					}
					a {
						display: inline;
						transition: all .25s;
						&:hover {
							box-shadow: none;
							opacity: .6;
						}

					}
				}
			}
		}
		.copyright {
			@extend .small-sansserif;
		}
	}
	.secondary {
		background-color: $white;
		color: $darkgrey;
		padding: $s-space 0;
		font-size: rem-calc(12);
		@include mdquery {
			text-align: center;
		}
		.large-7 {
			a {
				font-size: rem-calc(12);
				color: $darkgrey;
				box-shadow: 0 1px 0 $light-grey-2;
				transition: box-shadow .25s;
				&:hover {
					box-shadow: 0 1px 0 $red;
					color: $red;
				}
				&:nth-child(2) {
					margin-left: 20px; 
					@include smquery {
						margin-left: 0;
					}
					&:before {
						@include smquery {
							content: '';
							display: block;
							width: 100%;
							height: 1px;
							clear: both;
						}
					}
				}
			}
			p {
				margin-top: $xs-space;
			}
		}
		p {
			margin-bottom: 0;
		}
		.ecentric {
			text-align: right;
			@include mdquery {
				text-align: center;
				margin-top: 10px;
			}
			a {
				&:hover {
					box-shadow: none;
				}
			}
		}
	}
}