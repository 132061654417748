.list-item {
	margin-bottom: rem-calc(40);
	h3, h2 {
		margin-bottom: 10px !important;
		@include smquery {
			margin: $s-space 0 $xs-space 0 !important;
		}
	}
	img {
		display: block;
		width: 100%;
	}
	.thumb {
		margin-top: $xs-space;
		position: relative;
	}
	.content {
		.teaser {
			font-size: 1em;
		}
		.meta, 
		.grade-level {
			font-size: rem-calc(12);
			font-family: $medium-sansserif-font;
			color: $mediumgrey;
			.type {
				font-family: $heading-font;
				text-transform: uppercase;
			}
		}
		.grade-level::before {
			content: '';
			display: block;
			border-bottom: 1px solid $light-grey-2;
			width: 1.5rem;
			height: 1px;
			margin: 0.5em 0;
		}
	}
}

#ch-app-post {
	.stories-list {
		@include mdquery {
			margin-top: $m-space;
		}
		.story { 
			&:nth-child(odd) {
				clear: left;
			}
			
		}
	} 
}

// Rail items

.rail {
	.list-item {
		h2, h3 {
			margin-top: 0 !important;
			@include smquery {
				margin-top: $s-space !important;
			}
		}
	}
}