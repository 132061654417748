// Accessibility 

#accessibility-links {
	position: absolute;
	top: $s-space;
	min-width: 300px;	
	@include smquery {
		min-width: inherit;
	}
	a {
		left: -99999px;
		position: absolute;
		background-color: #3675b3;
		padding: $xs-space;
		color: $white;
		font-family: $regular-sansserif-font;
		&:focus {
			left: $s-space;
			z-index: 600;
		}
	}
}

.accessibility-link {
	position: absolute;
	left: -99999px;
	background-color: #3675b3!important;
	padding: $xs-space;
	color: $white!important;
	font-family: $regular-sansserif-font;
	&:focus {
		left: 0;
		position: relative;
		color: $white;
		margin-bottom: 15px;
	    display: inline-block;
	}
}