// Search

#search-component {
  display: none;
  &.active {
    display: block;
  }
  .search-inputs {
    background-color: $red;
    padding: $s-space 0;
    label {
      display: none;
    }
    input[type="text"] {
      border: 0;
      box-shadow: none;
      width: calc(100% - 105px);
      display: inline-block;
      margin-bottom: 0;
      font-family: $light-sansserif-font;
      font-size: rem-calc(18);
      @include border-radius(5px);
      &::-webkit-input-placeholder {
        color: $darkgrey;
      }
      &::-moz-placeholder {
        color: $darkgrey;
      }
      &:-ms-input-placeholder {
        color: $darkgrey;
      }
      &:-moz-placeholder {
        color: $darkgrey;
      }
    }
    .button {
      text-transform: uppercase;
      margin-bottom: 0;
      padding: 11px 15px;
      vertical-align: top;
      float: right;
    }
  }
  .search-results {
    background-color: $lightgrey;
    border-bottom: 1px solid $light-grey-2;
    position: absolute;
    width: 100%;
    z-index: 6000;
    .predictiveSearchResults {
      margin: $m-space 0;
      max-height: 350px;
      position: relative;
      overflow: auto;
      z-index: 6000;
      width: inherit;
      border: 0;
      background-color: transparent;
      .predictiveSearchResults > * {
        color: inherit;
        padding: inherit;
      }
      .predictiveSearchCategory {
        display: none;
      }
      .search-result {
        padding: 0;
        margin-bottom: $s-space;
        &:last-of-type {
          margin-bottom: 0;
        }
        a {
          color: $darkgrey;
          font-family: $light-sansserif-font;
          font-size: rem-calc(18);
        }
      }
    }
  }
}

// Rail search

.search-control {
  background-color: $lightgrey;
  border: 1px solid $light-grey-4;
  padding: $m-space;
  @include border-radius(5px);
  label {
    @extend .small-sansserif;
    text-transform: uppercase;
    font-family: $heading-font;
    margin-bottom: $s-space;
    color: $darkgrey;
  }
  .form-control {
    font-family: $light-sansserif-font;
  }
  .btn {
  }
}

// Search Results Page
.search-results-info {
  font-family: $regular-sansserif-font;
  border-bottom: 1px solid $light-grey-4;
  padding: 0 0 $s-space 0;
  margin-bottom: $m-space;
  @include smquery {
    margin-top: $m-space;
  }
}

// French

@include fronly {
  #search-component {
    .search-inputs {
      input[type="text"] {
        width: calc(100% - 128px);
      }
    }
  }
}

// Kids Search
.app-search-input {
  input[type="text"] {
    width: 100%;
    margin: 0;
    padding: 5px 5px 5px 10px;
    font-size: 14px;
    border-radius: 5px 0 0 5px;
    flex: 1;
  }
  button {
    @extend input, [type="submit"];
    padding: 5px 10px;
    margin: 0;
    font-size: 13px;
    border-radius: 0 5px 5px 0;
  }
  &--flex {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
  }
}
.offscreen {
  position: absolute;
  left: -9999px;
  height: 1px;
  top: -999px;
}
