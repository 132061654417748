.wallpaper {
  overflow-x: hidden;
  #page {
    min-height: 850px;
    position: relative;
    z-index: 10;
  }
}
#page {
  padding: $xl-space 0;
  min-height: 720px;
  @include smquery {
    padding: $m-space 0;
  }
  &.home {
    padding: 40px 0;
  }
}

// Lang

.ENCA .lang-fr {
  display: none;
}
.FRCA .lang-en {
  display: none;
}

// V align center

.valign-middle {
  display: table;
}

.valign-middle .columns {
  display: table-cell;
  vertical-align: middle;
}

.valign-middle .columns,
.valign-middle [class*="column"] + [class*="column"]:last-child {
  float: none;
}

// Type

html {
  font-size: $body-size;
  @include mdquery {
    //font-size: 16px;
  }
  &.menuOpen {
    overflow-y: hidden;
  }
}

body {
  font-family: $body-font;
  line-height: $body-line-height;
  color: $body-color;
  font-family: $body-font;
  background-color: $white;
}

h1,
.h1 {
  font-size: $h1-font-size;
}
h2,
.h2 {
  font-size: $h2-font-size;
}
h3,
.h3 {
  font-size: $h3-font-size;
}
h4,
.h4 {
  font-size: $h4-font-size;
}
h5,
.h5 {
  font-size: $h5-font-size;
  font-family: $medium-sansserif-font;
  color: $heading-color;
}
h6,
.h6 {
  font-size: $h6-font-size;
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h6,
.h1 {
  font-family: $heading-font;
  line-height: $heading-line-height;
  color: $heading-color;
}

h2.line {
  @extend .h4;
  margin: 0 0 $l-space 0;
  position: relative;
  text-transform: uppercase;
  span {
    background-color: #fff;
    display: inline-block;
    position: relative;
    z-index: 2;
    padding-right: 20px;
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    border-top: 1px solid $light-grey-4;
    top: -15px;
    position: relative;
  }
}

a {
  transition: background 0.25s, box-shadow 0.25s, color 0.25s;
  &.external {
    &::after {
      content: "";
      display: inline-block;
      height: 0.7rem;
      width: 0.7rem;
      background: url(../icons/external-link.svg) transparent;
      margin-left: 0.5em;
    }
  }
}

p {
  line-height: $body-line-height;
}
.no-gutter {
  margin-left: 0 !important;
  margin-right: 0 !important;
  .columns {
    padding-left: 0;
    padding-right: 0;
  }
}
.removeGenericLink {
  box-shadow: none;
  text-shadow: none;
}

.xsmall-serif {
  font-size: rem-calc(12px);
}
.small-serif {
  font-size: rem-calc(14px);
}
.medium-serif {
  font-size: $body-size;
}
.large-serif {
  font-size: rem-calc(24px);
  line-height: $heading-line-height;
}
.xsmall-sansserif {
  font-family: $regular-sansserif-font;
  font-size: rem-calc(10px);
}
.small-sansserif {
  font-family: $regular-sansserif-font;
  font-size: rem-calc(14px);
}
.medium-sansserif {
  font-family: $regular-sansserif-font;
}
.large-sansserif {
  font-family: $regular-sansserif-font;
  font-size: rem-calc(28px);
  line-height: $heading-line-height;
}

.xsmall-sansserif-bold {
  font-family: $heading-font;
  font-size: rem-calc(12px);
}
.small-sansserif-bold {
  font-family: $heading-font;
  font-size: rem-calc(14px);
}
.medium-sansserif-bold {
  font-family: $heading-font;
  font-size: $body-size;
}
.large-sansserif-bold {
  font-family: $heading-font;
  font-size: rem-calc(24px);
  line-height: $heading-line-height;
}

.box {
  margin-bottom: $l-space;
}

// Foundation Fixes

[class*="column"] + [class*="column"]:last-child {
  float: left;
}
