.CommentDetail {
  .CommentUserName {
    font-weight: bold;
  }
  
  .CommentDate {
    font-size: 0.8rem;
  }

  .CommentAction {
    font-size: 0.8rem;
    
    a {
      margin-right: 8px;
    }
    
    .ButtonPadding {
      margin-bottom: 8px;
    }
  }
}