.taglinks {
	h2 {
		font-family: $medium-sansserif-font;
		margin: $m-space 0 10px 0 !important;
		@extend .h5;
	}
	ul {
		list-style-type: none;
		margin-left: 0;
		margin: 0;
		padding-left: 0;
		li {
			display: inline-block;
			margin-bottom: 0;
			a {
				padding: 2.5px 10px;
				box-shadow: 0 0 1px 0px $light-grey-2 inset, 0 0 1px 0px $light-grey-2;
				color: $darkgrey;
				background-color: $white;
				border-radius: $xs-space;
				margin: 0 $xs-space 0 -0px;
				margin-bottom: $xs-space;
				text-shadow: none;
				display: block;
				@extend .small-sansserif;
				// Hover state
				&:hover, &:focus {
					box-shadow: 0 0 1px 0px $darkgrey inset, 0 0 1px 0px $darkgrey;
					background-color: $darkgrey;
					color: $white;
				}
			}
			&:first-of-type {
				margin-left: 0;
				a {
				}
			}
		}
	}
}

#two-columns-right-rail {
	.rail {
		@include smquery {
			margin-top: 30px;
		}
	}
}