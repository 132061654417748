// Story Details 
.wallpaper {
	.content {
		.main {
			min-height: 565px;
		}
	}
}
.content {
	.header {
		padding-top: $xl-space;
		margin-bottom: $xl-space;
		@include mdquery {
			padding-top: $s-space;
			margin-bottom: $m-space;
		}
		h1 {
			margin-bottom: $s-space;
			@include smquery {
				font-size: $h2-font-size;
			}
		}
		.sponsored-intro {
			background-color: $lightgrey;
			color: $darkgrey;
			padding: 7px 15px;
			@extend .small-sansserif;
			text-transform: uppercase;
			display: inline-block;
			font-family: $medium-sansserif-font;
			font-size: 0.55rem;
			margin-bottom: 20px;
			letter-spacing: 0.1em;
		}
		.teaser {
			color: $mediumgrey;
			padding: 0 0 rem-calc(45) 0;
			@extend .large-sansserif;
			@include smquery {
				font-size: $h3-font-size;
			}
			p {
				@extend .large-sansserif;
				margin-bottom: 0;
			}
		}
		.meta { 
			@extend .small-sansserif;
			@include mdquery {
				padding: $s-space 0;
			}
			.details {
				display: inline-block; 
				vertical-align: top;
				p {
					margin-bottom: 0;
					vertical-align: top;
					display: inline-block;
				}
			} 
			a {
				text-decoration: none;
				background: transparent;
				text-shadow: 0 1px 0 white;
				transition: background 0.25s,
					box-shadow 0.25s,
					color 0.25s;
				color: $darkestgrey;
				box-shadow: 0 3px 0 $primary-colour-1-hover;
				&:hover {
					text-shadow: none;
					background: $primary-colour-1-hover;
					color: $darkestgrey;
				}
			}
		}
	}
	.main {
		padding-bottom: $xl-space;
		.share-icons {
			text-align: left;
			margin-left: 0;
			margin-top: $m-space;
			li {
				a {
					//box-shadow: none;
				}
			}
		}
		.meta-extended {
			margin-top: $m-space;
			@extend .small-sansserif;
		}
		// .story-info used for lesson plan 
		// curricula code, grade level etc.
		.story-info {			
			h2 {
				margin-bottom: 0.5rem;
			}
			p {
				font-family: $regular-sansserif-font;
				margin-bottom: $s-space;
				font-size: 0.8rem;
				line-height: 1.2;
			}
		}
	}
}