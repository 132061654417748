// Linked Pages

#linked-pages {
	font-size: 16px;	
	background-color: $lightgrey;
	margin-bottom: -40px;
	h2 {
		font-size: 1.4em;
		margin-bottom: $m-space;
	}
	h3 {
		font-size: 1.2em;
	}
	.spacing {
		padding: $xl-space 0;
		@include smquery {
			padding: $m-space 0 0 0;
		}
		&:empty {
			padding: 0;
			margin: 0;
		}
	}
}