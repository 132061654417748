// Colours

.lightgrey { background-color: $lightgrey; }
.mediumlightgrey { background-color: $mediumlightgrey; }
.mediumgrey { background-color: $mediumgrey; }
.darkgrey { background-color: $darkgrey; }
.darkergrey { background-color: $darkergrey; }
.darkestgrey { background-color: $darkestgrey; }
.black { background-color: $black; }
.white { background-color: $white; }
.red { background-color: $red; }
.darkred { background-color: $darkred; }
.babyblue { background-color: $babyblue; }
.gold { background-color: $gold;  }

 