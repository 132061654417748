.media-embed {
	margin: 0 auto $m-space auto;
	display: table;
	.embedcode {
		text-align: center;
		iframe {
			max-width: 100%;
			display: inline-block;
		}
	}
	.caption {
		@extend .small-sansserif;
		margin: $s-space 0 10px 0;
		font-family: $medium-sansserif-font;
		display: table-caption;
		caption-side: bottom;
	}

	.transcript {
		margin-top: $s-space;
		a {
			@extend .medium-sansserif;
		}
	}

}