// Gallery thumbnails

.gallery {
	margin: 0 auto;
	position: relative;
	@include smquery {
		width: 100%;
	}
	.loader {
		position: absolute;
	}
	.gallery-thumbs {
		.thumbs {
			list-style-type: none;
			padding: 0;
			margin: 0 -5px 0 0;
			li {
				display: inline-block;
				vertical-align: top;
				width: calc(20% - 10px);
				margin: 0 5px 0 1px;
				img {
					max-height: 110px;
					width: auto;
				}
				a {
					@extend .removeGenericLink;
					&:hover {
						background: transparent;
					}
				}
				&.selected {
					border: 3px solid $red;
					img {
						opacity: 0.6;
					}
				}
			}
		}
	}

	.gallery-images {
		display: block;
		margin-bottom: 10px;
		.slideshow {
			position: relative;
			.image-wrapper {
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		.content {
			display: block;
			position: relative;
			font-size: rem-calc(14);
			background-color: #555555;
			color: $white;
			padding: rem-calc(25px);
			min-height: 135px;
			.controls {
				margin-bottom: $s-space;
				a {
					font-family: $heading-font;
					text-transform: uppercase;
					letter-spacing: .5px;
					@extend .removeGenericLink;
					font-size: rem-calc(12);
					color: $white;
					text-transform: uppercase;
					span {
						border-bottom: 0.5px solid $mediumlightgrey;
						transition: border 0.25s;
					}
					&:hover {
						background-color: inherit;
						span {
							border-bottom: 2px solid $mediumlightgrey;
						}
					}
					&.prev {
						&:before {
							content: " ‹ ";
							padding: 0 2.5px 0 0;
						}
						&:after {
							content: " / ";
							padding: 0 5px;
						}
					}	
					&.next {
						&:after {
							content: " › ";
							padding: 0 0 0 2.5px;
						}
					}
				}
			}
			.caption {
				margin: 0;
				font-family: $light-sansserif-font;
				.photo-caption {
					margin-top: rem-calc(10);
				}
				.photo-credit {
					@extend .xsmall-sansserif;
					text-transform: uppercase;
					font-family: $medium-sansserif-font;
					margin-top: $s-space;
				}
			}
		}
	}
	.pagination {
		padding: 0;
	}
}
