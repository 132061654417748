@import url("//fonts.googleapis.com/css?family=Crimson+Text:400,400i,700,700i");
@font-face {
  font-family: "AvenirNextLTW01-Regular";
  src: url("./fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix");
  src: url("./fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix")
      format("eot"),
    url("./fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"),
    url("./fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"),
    url("./fonts/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype"),
    url("./fonts/52a192b1-bea5-4b48-879f-107f009b666f.svg#52a192b1-bea5-4b48-879f-107f009b666f")
      format("svg");
}
@font-face {
  font-family: "AvenirNextLTW01-Italic";
  src: url("./fonts/d1fddef1-d940-4904-8f6c-17e809462301.eot?#iefix");
  src: url("./fonts/d1fddef1-d940-4904-8f6c-17e809462301.eot?#iefix")
      format("eot"),
    url("./fonts/7377dbe6-f11a-4a05-b33c-bc8ce1f60f84.woff2") format("woff2"),
    url("./fonts/92b66dbd-4201-4ac2-a605-4d4ffc8705cc.woff") format("woff"),
    url("./fonts/18839597-afa8-4f0b-9abb-4a30262d0da8.ttf") format("truetype"),
    url("./fonts/1de7e6f4-9d4d-47e7-ab23-7d5cf10ab585.svg#1de7e6f4-9d4d-47e7-ab23-7d5cf10ab585")
      format("svg");
}
@font-face {
  font-family: "Avenir Next LT W01 Demi";
  src: url("./fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix");
  src: url("./fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix")
      format("eot"),
    url("./fonts/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2") format("woff2"),
    url("./fonts/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format("woff"),
    url("./fonts/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf") format("truetype"),
    url("./fonts/99affa9a-a5e9-4559-bd07-20cf0071852d.svg#99affa9a-a5e9-4559-bd07-20cf0071852d")
      format("svg");
}
@font-face {
  font-family: "AvenirNextLTW01-DemiIta";
  src: url("./fonts/770d9a7e-8842-4376-9319-8f2c8b8e880d.eot?#iefix");
  src: url("./fonts/770d9a7e-8842-4376-9319-8f2c8b8e880d.eot?#iefix")
      format("eot"),
    url("./fonts/687932cb-145b-4690-a21d-ed1243db9e36.woff2") format("woff2"),
    url("./fonts/bc350df4-3100-4ce1-84ce-4a5363dbccfa.woff") format("woff"),
    url("./fonts/bc13ae80-cd05-42b4-b2a9-c123259cb166.ttf") format("truetype"),
    url("./fonts/4862b373-2643-46b1-b0b5-88537c52d15c.svg#4862b373-2643-46b1-b0b5-88537c52d15c")
      format("svg");
}
@font-face {
  font-family: "Avenir Next LT W01 Bold";
  src: url("./fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix");
  src: url("./fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix")
      format("eot"),
    url("./fonts/14c73713-e4df-4dba-933b-057feeac8dd1.woff2") format("woff2"),
    url("./fonts/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff") format("woff"),
    url("./fonts/890bd988-5306-43ff-bd4b-922bc5ebdeb4.ttf") format("truetype"),
    url("./fonts/ed104d8c-7f39-4e8b-90a9-4076be06b857.svg#ed104d8c-7f39-4e8b-90a9-4076be06b857")
      format("svg");
}
@font-face {
  font-family: "AvenirNextLTW01-BoldIta";
  src: url("./fonts/ac2d4349-4327-448f-9887-083a6a227a52.eot?#iefix");
  src: url("./fonts/ac2d4349-4327-448f-9887-083a6a227a52.eot?#iefix")
      format("eot"),
    url("./fonts/eaafcb26-9296-4a57-83e4-4243abc03db7.woff2") format("woff2"),
    url("./fonts/25e83bf5-47e3-4da7-98b1-755efffb0089.woff") format("woff"),
    url("./fonts/4112ec87-6ded-438b-83cf-aaff98f7e987.ttf") format("truetype"),
    url("./fonts/ab1835cb-df6f-4d8d-b8ee-3075f5ba758d.svg#ab1835cb-df6f-4d8d-b8ee-3075f5ba758d")
      format("svg");
}
/*
@font-face{
    font-family:"AvenirNextLTW01-UltraLi";
    src:url("./fonts/17c55b27-e9ab-43cd-b948-d49f97480d68.eot?#iefix");
    src:url("./fonts/17c55b27-e9ab-43cd-b948-d49f97480d68.eot?#iefix") format("eot"),url("./fonts/2e3f5cb9-101f-46cf-a7b3-dfaa58261e03.woff2") format("woff2"),url("./fonts/fa19948e-5e38-4909-b31e-41acd170d6f2.woff") format("woff"),url("./fonts/6de0ce4d-9278-467b-b96f-c1f5f0a4c375.ttf") format("truetype"),url("./fonts/9fd4ea0c-b19a-4b21-9fdf-37045707dd78.svg#9fd4ea0c-b19a-4b21-9fdf-37045707dd78") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-UltraLt_721260";
    src:url("./fonts/8b498c39-fabe-4789-bb81-5e37c9ecddd3.eot?#iefix");
    src:url("./fonts/8b498c39-fabe-4789-bb81-5e37c9ecddd3.eot?#iefix") format("eot"),url("./fonts/1c6c1881-23c9-4d6a-a018-759d1c9ded15.woff2") format("woff2"),url("./fonts/f0d655c5-a88a-49ad-bb29-f3b5cca41731.woff") format("woff"),url("./fonts/3a561c83-40d4-4868-8255-e8455eb009c4.ttf") format("truetype"),url("./fonts/3966f856-9dcf-48e7-88e7-7400f1b7d619.svg#3966f856-9dcf-48e7-88e7-7400f1b7d619") format("svg");
}
@font-face{
    font-family:"Avenir Next W01 Thin";
    src:url("./fonts/42c793cf-df5a-4e07-bf3e-37de54df02b6.eot?#iefix");
    src:url("./fonts/42c793cf-df5a-4e07-bf3e-37de54df02b6.eot?#iefix") format("eot"),url("./fonts/64779e28-a3b8-4364-949c-771372a0a327.woff2") format("woff2"),url("./fonts/c6c8e4be-17eb-4475-bbfc-bb485ffde766.woff") format("woff"),url("./fonts/87a872d0-5af3-4231-a77c-37f41ec74662.ttf") format("truetype"),url("./fonts/91464696-717b-4eaa-adbd-0dfc5e5fd782.svg#91464696-717b-4eaa-adbd-0dfc5e5fd782") format("svg");
}
@font-face{
    font-family:"AvenirNextW01-ThinItali";
    src:url("./fonts/41537dd7-434f-4f85-b9d9-a88b160bc18d.eot?#iefix");
    src:url("./fonts/41537dd7-434f-4f85-b9d9-a88b160bc18d.eot?#iefix") format("eot"),url("./fonts/ec2dd1bd-4391-4b89-bf85-1a2346ea4342.woff2") format("woff2"),url("./fonts/fcf6f211-efa2-40f6-9c6b-e0ca63b6ea60.woff") format("woff"),url("./fonts/60fcc194-9529-4b99-9506-cdb26642656a.ttf") format("truetype"),url("./fonts/c110f1ef-6fdf-4008-bc75-b370649384a3.svg#c110f1ef-6fdf-4008-bc75-b370649384a3") format("svg");
}
@font-face{
    font-family:"Avenir Next W01 Light";
    src:url("./fonts/0ecef829-586e-426d-a633-4c8a8c926769.eot?#iefix");
    src:url("./fonts/0ecef829-586e-426d-a633-4c8a8c926769.eot?#iefix") format("eot"),url("./fonts/49c5f3e1-5867-4b1d-a843-2d07fa60d85d.woff2") format("woff2"),url("./fonts/f401df6f-54eb-406c-b46a-90b9451c598d.woff") format("woff"),url("./fonts/276fe25e-e430-4376-b33c-705dd77b3dba.ttf") format("truetype"),url("./fonts/869dc33b-3685-4d74-a51c-82b435579093.svg#869dc33b-3685-4d74-a51c-82b435579093") format("svg");
}
@font-face{
    font-family:"AvenirNextW01-LightItal";
    src:url("./fonts/440b64c9-a97b-4a0c-a6af-a6bb6d49c252.eot?#iefix");
    src:url("./fonts/440b64c9-a97b-4a0c-a6af-a6bb6d49c252.eot?#iefix") format("eot"),url("./fonts/62e28d52-436d-40b8-a5f0-00479f3f44ed.woff2") format("woff2"),url("./fonts/5b8c6fdc-0ba4-4b65-87e4-d5ae51245ea5.woff") format("woff"),url("./fonts/c677d79d-442e-4c76-8db1-c29ac7788039.ttf") format("truetype"),url("./fonts/216291a0-f927-4f75-9de7-6e36c7fd7ebf.svg#216291a0-f927-4f75-9de7-6e36c7fd7ebf") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-Medium";
    src:url("./fonts/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix");
    src:url("./fonts/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix") format("eot"),url("./fonts/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2") format("woff2"),url("./fonts/f26faddb-86cc-4477-a253-1e1287684336.woff") format("woff"),url("./fonts/63a74598-733c-4d0c-bd91-b01bffcd6e69.ttf") format("truetype"),url("./fonts/a89d6ad1-a04f-4a8f-b140-e55478dbea80.svg#a89d6ad1-a04f-4a8f-b140-e55478dbea80") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-MediumI_721278";
    src:url("./fonts/77a9cdce-ea6a-4f94-95df-e6a54555545e.eot?#iefix");
    src:url("./fonts/77a9cdce-ea6a-4f94-95df-e6a54555545e.eot?#iefix") format("eot"),url("./fonts/3f380a53-50ea-4a62-95c5-d5d8dba03ab8.woff2") format("woff2"),url("./fonts/8344e877-560d-44d4-82eb-9822766676f9.woff") format("woff"),url("./fonts/b28b01d9-78c5-46c6-a30d-9a62c8f407c5.ttf") format("truetype"),url("./fonts/ed4d3c45-af64-4992-974b-c37cd12a9570.svg#ed4d3c45-af64-4992-974b-c37cd12a9570") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-Heavy";
    src:url("./fonts/3418f6be-70a5-4c26-af1d-c09a8642ca20.eot?#iefix");
    src:url("./fonts/3418f6be-70a5-4c26-af1d-c09a8642ca20.eot?#iefix") format("eot"),url("./fonts/5c57b2e2-f641-421e-a95f-65fcb47e409a.woff2") format("woff2"),url("./fonts/181c847e-cdbc-43d5-ae14-03a81c8953b4.woff") format("woff"),url("./fonts/045d1654-97f2-4ff0-9d24-21ba9dfee219.ttf") format("truetype"),url("./fonts/3c111f4f-c9f7-45d4-b35f-4f4ed018842f.svg#3c111f4f-c9f7-45d4-b35f-4f4ed018842f") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-HeavyIt_721290";
    src:url("./fonts/ca9162bc-20bd-4810-91a9-e816fdc64dae.eot?#iefix");
    src:url("./fonts/ca9162bc-20bd-4810-91a9-e816fdc64dae.eot?#iefix") format("eot"),url("./fonts/71b9791b-4350-4b52-8b43-c03b82004511.woff2") format("woff2"),url("./fonts/8c17992f-c017-49e0-b573-779f62016f06.woff") format("woff"),url("./fonts/2b4885a7-fc02-4aa0-b998-5b008a589c80.ttf") format("truetype"),url("./fonts/9b40a6ef-0ef5-49c0-aa8d-5ba7e8e7d9b7.svg#9b40a6ef-0ef5-49c0-aa8d-5ba7e8e7d9b7") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-UltraLt_721293";
    src:url("./fonts/8ce12460-588e-4471-af8f-7d6d72435606.eot?#iefix");
    src:url("./fonts/8ce12460-588e-4471-af8f-7d6d72435606.eot?#iefix") format("eot"),url("./fonts/4e85b83c-23d4-44fb-9b42-b7d35d7fdbdc.woff2") format("woff2"),url("./fonts/a09b5aae-fed9-40e0-9990-20eeaece9255.woff") format("woff"),url("./fonts/0fd9370c-b031-4de3-a183-228b47c8ad56.ttf") format("truetype"),url("./fonts/a30a07b5-d5bc-4f00-a949-20f62723d116.svg#a30a07b5-d5bc-4f00-a949-20f62723d116") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-UltLtCn_721296";
    src:url("./fonts/a67ef414-c5d7-468b-b47e-d184186ab1af.eot?#iefix");
    src:url("./fonts/a67ef414-c5d7-468b-b47e-d184186ab1af.eot?#iefix") format("eot"),url("./fonts/0af7c703-9511-413c-b2b0-0028ee78523f.woff2") format("woff2"),url("./fonts/31e6842e-9840-4bbe-8a92-47e14d532a18.woff") format("woff"),url("./fonts/e681b22e-866c-4ee6-ab86-10a2ed50dbb3.ttf") format("truetype"),url("./fonts/025a5227-5321-4f52-9c20-85176659a709.svg#025a5227-5321-4f52-9c20-85176659a709") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-Condens_721299";
    src:url("./fonts/f737d53f-f6ed-4f15-8fe7-925fb68a8851.eot?#iefix");
    src:url("./fonts/f737d53f-f6ed-4f15-8fe7-925fb68a8851.eot?#iefix") format("eot"),url("./fonts/7fb4cb78-601f-4bb2-995a-442252089573.woff2") format("woff2"),url("./fonts/19db35b6-b3e8-400b-ae62-a3d37cc9fa56.woff") format("woff"),url("./fonts/61860563-e9eb-40bd-844e-48e96af9d063.ttf") format("truetype"),url("./fonts/94f6e6be-004d-4b3a-8b0e-f07609dc1726.svg#94f6e6be-004d-4b3a-8b0e-f07609dc1726") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-Condens_721302";
    src:url("./fonts/8e7f81d1-94dd-47e6-94ce-9c278d5d1ecc.eot?#iefix");
    src:url("./fonts/8e7f81d1-94dd-47e6-94ce-9c278d5d1ecc.eot?#iefix") format("eot"),url("./fonts/f31d487c-aab1-4ce0-8fb8-4bdfa26dd539.woff2") format("woff2"),url("./fonts/6b0725b6-26e5-4bcf-8174-4d4b4d4217b9.woff") format("woff"),url("./fonts/435f931d-7af1-4f3a-a084-3d7aed6cd568.ttf") format("truetype"),url("./fonts/962508db-d57e-4eb1-a483-28a105ee2b25.svg#962508db-d57e-4eb1-a483-28a105ee2b25") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-MediumC_721311";
    src:url("./fonts/10236419-1766-4da0-9a4c-83b4fffea025.eot?#iefix");
    src:url("./fonts/10236419-1766-4da0-9a4c-83b4fffea025.eot?#iefix") format("eot"),url("./fonts/c1ecdeb5-a61a-4207-ae23-0441885ba32d.woff2") format("woff2"),url("./fonts/4a75c3b0-f2ef-402c-b54b-ed543933e9c5.woff") format("woff"),url("./fonts/bb18d7e3-286e-4825-bf91-7fbeaf607d05.ttf") format("truetype"),url("./fonts/9c91e8bb-b452-4e4f-b00c-dad5dadb10c6.svg#9c91e8bb-b452-4e4f-b00c-dad5dadb10c6") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-MediumC_721314";
    src:url("./fonts/c705e79c-88bf-49cf-b054-d2cd2b700cb0.eot?#iefix");
    src:url("./fonts/c705e79c-88bf-49cf-b054-d2cd2b700cb0.eot?#iefix") format("eot"),url("./fonts/5d02d040-4b68-4c3d-bd11-ef4664ff33cf.woff2") format("woff2"),url("./fonts/d1710add-9d03-4bda-b16e-c46b21ccaa64.woff") format("woff"),url("./fonts/398bc882-7cc8-4fce-a487-abe01122ff34.ttf") format("truetype"),url("./fonts/d5cfa01e-526b-462e-8873-2bb24ebad1d3.svg#d5cfa01e-526b-462e-8873-2bb24ebad1d3") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-DemiCn";
    src:url("./fonts/fe4d96a8-3161-4f0c-ac51-ed6a5df15918.eot?#iefix");
    src:url("./fonts/fe4d96a8-3161-4f0c-ac51-ed6a5df15918.eot?#iefix") format("eot"),url("./fonts/d268cd4d-987c-468a-a9bb-685df57a3892.woff2") format("woff2"),url("./fonts/add96dac-4679-4981-9390-74fd5a5bf61f.woff") format("woff"),url("./fonts/60f1dfd0-eea9-448c-84a0-e4e8d5ba7516.ttf") format("truetype"),url("./fonts/f9659813-f8c7-4548-9117-b8e02fa26641.svg#f9659813-f8c7-4548-9117-b8e02fa26641") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-DemiCnI_721308";
    src:url("./fonts/59849657-f79e-4c9c-807e-cf3bdaf6cd8c.eot?#iefix");
    src:url("./fonts/59849657-f79e-4c9c-807e-cf3bdaf6cd8c.eot?#iefix") format("eot"),url("./fonts/e56d2786-8357-40f9-910f-27c0b9247d4b.woff2") format("woff2"),url("./fonts/0053d44b-70fb-4049-bb99-4e049090191f.woff") format("woff"),url("./fonts/e317c755-2435-4d10-8756-67a0179564a5.ttf") format("truetype"),url("./fonts/a4ba042b-90d1-4acd-baea-706dee7fa81b.svg#a4ba042b-90d1-4acd-baea-706dee7fa81b") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-BoldCn";
    src:url("./fonts/07690b8a-c348-4c53-8de3-59f7cfef9ee8.eot?#iefix");
    src:url("./fonts/07690b8a-c348-4c53-8de3-59f7cfef9ee8.eot?#iefix") format("eot"),url("./fonts/9f6106aa-36e0-4cfb-8cda-c3354eab2461.woff2") format("woff2"),url("./fonts/ff039068-be8b-415c-b1a9-24eedee1c3ab.woff") format("woff"),url("./fonts/cdd2cecb-bd0e-41e5-9ecd-ef60f52a35be.ttf") format("truetype"),url("./fonts/390c08bc-8d21-4af3-95a1-a73c21f189cd.svg#390c08bc-8d21-4af3-95a1-a73c21f189cd") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-BoldCnI_721320";
    src:url("./fonts/34b322c2-c1c5-40af-b8e1-fb7574cf68e4.eot?#iefix");
    src:url("./fonts/34b322c2-c1c5-40af-b8e1-fb7574cf68e4.eot?#iefix") format("eot"),url("./fonts/741a7814-dce9-4eba-a17d-7a235d91ee46.woff2") format("woff2"),url("./fonts/8a911a4d-274f-4996-8857-e2c1d6b42d80.woff") format("woff"),url("./fonts/9b626f1d-8523-44d7-b47e-37ac358725b0.ttf") format("truetype"),url("./fonts/704e9f93-4543-421e-861d-7246e89734f7.svg#704e9f93-4543-421e-861d-7246e89734f7") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-HeavyCn_721323";
    src:url("./fonts/f65301e6-bde6-475a-9ec4-e717c9071ec0.eot?#iefix");
    src:url("./fonts/f65301e6-bde6-475a-9ec4-e717c9071ec0.eot?#iefix") format("eot"),url("./fonts/8f88bcd5-8815-42e7-8ad4-500a79ee3896.woff2") format("woff2"),url("./fonts/8ed534f4-eeda-4572-abe3-72d0fdca7b2e.woff") format("woff"),url("./fonts/76586a32-27c7-46d6-9fd2-1be776727919.ttf") format("truetype"),url("./fonts/f5e460ec-29d2-437c-ace7-3dd23e7ae460.svg#f5e460ec-29d2-437c-ace7-3dd23e7ae460") format("svg");
}
@font-face{
    font-family:"AvenirNextLTW01-HeavyCn_721326";
    src:url("./fonts/574f016f-df72-4bf4-8574-4a04160ad2df.eot?#iefix");
    src:url("./fonts/574f016f-df72-4bf4-8574-4a04160ad2df.eot?#iefix") format("eot"),url("./fonts/026d994c-7246-43bf-892d-a3bd8e20d9fb.woff2") format("woff2"),url("./fonts/0416d7a9-ca05-4880-9820-100b1014b8e8.woff") format("woff"),url("./fonts/7bd1a09c-45cb-4bf8-bff2-015e69d58286.ttf") format("truetype"),url("./fonts/9b9e7f1d-5c52-42b0-bb41-dabe370775ef.svg#9b9e7f1d-5c52-42b0-bb41-dabe370775ef") format("svg");
}
@font-face{
    font-family:"Avenir Next W01 Cn Thin";
    src:url("./fonts/4998b3c8-f377-458d-9143-48ca2f5643bf.eot?#iefix");
    src:url("./fonts/4998b3c8-f377-458d-9143-48ca2f5643bf.eot?#iefix") format("eot"),url("./fonts/f93a7ba1-8ac9-422b-85ab-09c9138324de.woff2") format("woff2"),url("./fonts/98622b92-1cca-4c15-b19d-62b9c110cdb1.woff") format("woff"),url("./fonts/c20684a6-2733-4568-9708-8c4a7dfb4a5c.ttf") format("truetype"),url("./fonts/b126092f-08f5-4f0a-8f08-3e9fdbb5b21e.svg#b126092f-08f5-4f0a-8f08-3e9fdbb5b21e") format("svg");
}
@font-face{
    font-family:"AvenirNextW01-CnThinIta";
    src:url("./fonts/6c9b8b7e-5e1a-4813-9a08-bef545cc4a12.eot?#iefix");
    src:url("./fonts/6c9b8b7e-5e1a-4813-9a08-bef545cc4a12.eot?#iefix") format("eot"),url("./fonts/df8a8fcc-2f61-4eeb-82c6-a317c4ab1c89.woff2") format("woff2"),url("./fonts/4a25dc4d-e5c9-4fd1-8f90-c3558ecc4b93.woff") format("woff"),url("./fonts/e9842e10-9c7b-481c-a354-9dcd65ea4ef0.ttf") format("truetype"),url("./fonts/798dbe11-6b08-49bf-b9fb-686ae6189b51.svg#798dbe11-6b08-49bf-b9fb-686ae6189b51") format("svg");
}
@font-face{
    font-family:"AvenirNextW01-CnLight";
    src:url("./fonts/7402f25d-4872-4227-90cf-66f66bc51b3d.eot?#iefix");
    src:url("./fonts/7402f25d-4872-4227-90cf-66f66bc51b3d.eot?#iefix") format("eot"),url("./fonts/cc0dbd66-bf64-4956-a5b8-66defe0680d4.woff2") format("woff2"),url("./fonts/1c842023-250d-448b-91ac-52441c066cb2.woff") format("woff"),url("./fonts/096bed6d-1686-43c6-894f-04c0f3bbf2f1.ttf") format("truetype"),url("./fonts/9ebde751-0201-4b52-a97b-03735fe6b845.svg#9ebde751-0201-4b52-a97b-03735fe6b845") format("svg");
}
@font-face{
    font-family:"AvenirNextW01-CnLightIt";
    src:url("./fonts/a9f5d435-1343-4b1f-bd2f-15749f15e3fb.eot?#iefix");
    src:url("./fonts/a9f5d435-1343-4b1f-bd2f-15749f15e3fb.eot?#iefix") format("eot"),url("./fonts/536c7bb0-e31a-4d71-9e7c-3e46483a3465.woff2") format("woff2"),url("./fonts/fa081579-43f1-4d2c-b044-bf2591b9f90d.woff") format("woff"),url("./fonts/7a3678f7-0048-425a-821a-a51cfc18c7bb.ttf") format("truetype"),url("./fonts/4ac2b0ee-8cde-4460-a55a-bc8b93e9486f.svg#4ac2b0ee-8cde-4460-a55a-bc8b93e9486f") format("svg");
}
*/
