$base-line-height: 24px;
//$darkgrey: rgb(255,255,255);
$off-white: rgba($darkgrey, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {  
  50% { 
    background: $darkgrey;
  } 
}

.loader {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(255,255,255,.5);
  z-index: 1;
}
.load-icon {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -3px;
  margin-top: -12px;
}

.loading {
    border-radius: 50%;
    width: $base-line-height;
    height: $base-line-height;
    border: .25rem solid $off-white;
    border-top-color: $darkgrey;
    animation: spin $spin-duration infinite linear;
  &--double {
    border-style: double;
    border-width: .5rem;
  }
}

.loading-pulse {
  position: relative;
  width: ($base-line-height / 4);
  height: $base-line-height;
  background: $off-white;
  animation: pulse $pulse-duration infinite;
  animation-delay: ($pulse-duration / 3);
  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    height: ($base-line-height / 1.5);
    width: ($base-line-height / 4);
    background: $off-white;
    top: 50%;
    transform: translateY(-50%);
    animation: pulse $pulse-duration infinite;
  }
  &:before {
    left: -($base-line-height / 2);
  }
  &:after {
    left: ($base-line-height / 2);
    animation-delay: ($pulse-duration / 1.5);
  }
}


/*.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

// http://tobiasahlin.com/spinkit/
.spinner {
  width: 40px;
  height: 40px;
  background-color: #FFF;
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px)
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg)
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
*/