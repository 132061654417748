.body, main {
	h1, .h1 {
		line-height: 1.2;
	}
	
	h2, h3, h4, h5 {
		margin-top: $m-space;
		&:first-child {
			margin-top: 0;
			//margin-bottom: $m-space;
		}
	} 
	p {
		margin-bottom: rem-calc(30);
		&:empty {
			margin-bottom: 0;
		}
	}
	.small-text {
    font-size: rem-calc(14);
  }
	hr {
		border-bottom: 1px solid $light-grey-4;
		margin: 0 0 40px 0;
	}
	.image {
		margin-bottom: $l-space;
		.tag {
			font-family: $heading-font;
			margin-top: $s-space;
			small {
				margin-top: 5px;
				display: block;
				text-transform: inherit;
				font-family: $regular-sansserif-font;
			}
		}
	}
	strong, b {
	}
	i, em {
	}

	ul {
		margin-top: $m-space;  
		padding-left: $m-space;
		@include smquery {
			padding-left: 0;
		}
		li {	
			margin-bottom: $s-space;
			ul {
				margin-top: $s-space;
				list-style-type: none;
				padding-left: 0;
				margin-left: $s-space;
				li {
					margin-bottom: 0;
					clear: both;
					font-size: rem-calc(18);
					margin-bottom: $s-space;
					@include smquery {
						padding-left: 20px;
					}
					&:before {
						content: '- ';
						float: left;
						margin-left: -$s-space;
					}
				}
			}
		}
	}
	ol {
		list-style-type: none; 
		counter-reset: section;
		margin-top: $m-space;
		@include smquery {
			margin-left: 5px;
		}
		> li {
			margin-bottom: $s-space;
			clear: both;

			&:before {
				counter-increment: section;
				content: counter(section) ". ";
				color: $red;
				float: left;
				margin: 0 $s-space $s-space 0;
				font-style: italic;
				font-size: rem-calc(28);
				line-height: 1.2;
				
			}
			ol {
				list-style-type: lower-alpha;
				margin-top: $s-space;
				li {   
					list-style-position: inside;
					margin-bottom: $s-space;
					font-size: rem-calc(18);
					@include smquery {
						list-style-position: outside;
						margin-left: 40px;
					}
					&:before {
						content: '';
						@include smquery {
							display: none;
						}
					}
					ol {
						li {
							&:before {
								
							}
						}
					}
				}
			}
		}
	}
}
.body, .main {
	a {
		text-decoration: none;
		background: transparent;
		text-shadow: 0 1px 0 white;
		transition: background 0.25s,
			box-shadow 0.25s,
			color 0.25s;
		color: $darkestgrey;
		box-shadow: 0 3px 0 $primary-colour-1-hover;
		&:hover {
			text-shadow: none;
			background: $primary-colour-1-hover;
			color: $darkestgrey;
		}
	}
}

