// Listicle

.listicle {
	&.left {
		.image {
			float: left;
			margin: $xs-space $m-space $m-space 0;
			@include smquery {
				float: none;
				margin: $m-space 0;
			}
		}
	}
	&.right {
		.image {
			float: right;
			margin: $xs-space 0 $m-space $m-space;
			@include smquery {
				float: none;
				margin: $m-space 0; 
			}
		}
	}
	.image {
		.img-container {
			display: table;
			img {
				max-width: 335px;
				@include smquery {
					max-width: 100%;
				}
			}
			.image-caption {
				display: table-caption;
				caption-side: bottom;
				@extend .small-sansserif;
				margin: $s-space 0 0 0;
				font-family: $medium-sansserif-font;
			}
		}
	}

}