// Rail

.rail {
	font-size: 16px;
	h2 {
		margin-top: 0;
		font-size: 1.4em;
		margin-bottom: $m-space;
		line-height: 1;
		&.line {
			text-transform: inherit;
			&:after {
				display: none;
			}
		}
	}
	h3 {
		font-size: 1.2em;
		margin-top: 0;
	}
}