@media print {
	a[href]:after {
		content: '';
	}
	.utility-nav, .primary-nav, #top-banners, #hero-nav {
		display: none;
	}
	#page.home {
		padding: 20px 0;
	}
	.content {
		.header {
			padding-top: 0;
			margin-bottom: 0;
			padding: 20px 0;
		}
		.main {
			padding-bottom: 0;
		}
	}
	.list-item, .tile-item {
		.thumb {
			display: none;
		}
	}
	.rail {
		margin-top: 30px;
	}
	.spacing {
		padding-top: 0px !important;
		h2 {
			margin-bottom: 10px !important; 
		}
	}
	#main-footer {
		.cta-left, .footer-sponsors {
			display: none;
		}
		.left-info {
			.socialmedia {
				display: none;
			}
			.footer-info a {
				margin-top: 10px 0 !important;
			}
		}
		.primary {
			padding: 0 !important;
		}
		.general {
			margin-top: 20px !important;
		}
	}
}