// Nav Items

.icon-ggawards {
  background-image: url(../icons/ggha-icon.svg);
}

// Class icons

.icon {
  @include background-size(100%);
  background-repeat: no-repeat;
}
.icon-burger {
  background-image: url(../icons/hamburger.svg);
}
.icon-search {
  background-image: url(../icons/search.svg);
}
.icon-search-dark {
  background-image: url(../icons/search-dark.svg);
}
.icon-close-white {
  background-image: url(../icons/close.svg);
}
.icon-chevron-down-white {
  background-image: url(../icons/drop-down-arrow.svg);
}
.icon-chevron-down-grey {
  background-image: url(../icons/drop-down-arrow-hover.svg);
}
.icon-chevron-up-white {
  background-image: url(../icons/drop-down-arrow.svg);
  @include transform(rotate(180deg));
}

// Filters

.icon-o-plus-dark {
  background-image: url("../icons/filter-expand-dark.svg");
}
.icon-o-plus-light {
  background-image: url("../icons/filter-expand-light.svg");
}
.icon-o-minus-dark {
  background-image: url("../icons/filter-minimize-dark.svg");
}
.icon-o-minus-light {
  background-image: url("../icons/filter-minimize-light.svg");
}

// Filter Specifics
%icon-filter-category {
  background-image: url(../icons/filters/filter-category.svg);
}
%icon-filter-era {
  background-image: url(../icons/filters/filter-era.svg);
}
%icon-filter-format {
  background-image: url(../icons/filters/filter-format.svg);
}
%icon-filter-geography {
  background-image: url(../icons/filters/filter-geography.svg);
}
%icon-filter-grade-level {
  background-image: url(../icons/filters/filter-grade-level.svg);
}
%icon-filter-theme {
  background-image: url(../icons/filters/filter-theme.svg);
}
%icon-filter-year {
  background-image: url(../icons/filters/filter-year.svg);
}
%icon-filter-search {
  background-image: url(../icons/filters/filter-search.svg);
}
%icon-filter-province {
  background-image: url(../icons/filters/filter-location.svg);
}
%icon-filter-year {
  background-image: url(../icons/filters/filter-calendar.svg);
}

// Placeholders (for pseudo elements)

%icon {
  @include background-size(100%);
  background-repeat: no-repeat;
}
%icon-burger {
  background-image: url(../icons/hamburger.svg);
}
%icon-search {
  background-image: url(../icons/search.svg);
}
%icon-search-dark {
  background-image: url(../icons/search-dark.svg);
}
%icon-close-white {
  background-image: url(../icons/close.svg);
}
%icon-chevron-down-white {
  background-image: url(../icons/drop-down-arrow.svg);
}
%icon-chevron-down-grey {
  background-image: url(../icons/drop-down-arrow-hover.svg);
}
%icon-chevron-up-white {
  background-image: url(../icons/drop-down-arrow.svg);
  @include transform(rotate(180deg));
}
%icon-plus-darkgrey {
  background-image: url(../icons/mobile-expand.svg);
}
%icon-minus-darkgrey {
  background-image: url(../icons/mobile-collapse.svg);
}
%icon-plus-red {
  background-image: url(../icons/mobile-expand-hover.svg);
}
%icon-minus-red {
  background-image: url(../icons/mobile-collapse-hover.svg);
}
%icon-ggawards {
  background-image: url(../icons/ggha-icon.svg);
  width: 45px;
  height: 37px;
}
