
// Colors

$lightgrey: #f3f3f3;
$mediumlightgrey: #a1a1a1;
$mediumgrey: #5f5f5f;
$darkgrey: #292929;
$darkergrey: #212121;
$darkestgrey: #080808;
$black: #000;
$white: #fff;
$red: #e21936;
$darkred: #b5142c;
$babyblue: #b7e4fb;
$gold: #9e6c26; 
$lightgold: #CBA20B;
$light-grey-2: #dbdbdb;
$light-grey-3: #6f6f6f;
$light-grey-4: #e4e5e5;
$green: #008000;

$primary-colour-1-hover: $babyblue;
$success-color: $green;

// Paddings and Margins

$xl-space: 60px;
$l-space: 40px;
$m-space: 30px;
$s-space: 15px;
$xs-space: 5px;


// Blockquotes

$blockquote-left-border: 0;
$blockquote-border-colour: $red; 
$blockquote-font: 'Crimson Text';
$blockquote-colour: $darkgrey;
$blockquote-font-size: rem-calc(36px); 
$blockquote-margin-bottom: 0;
$cite-margin-top: $s-space;
$cite-colour: $mediumgrey;  

// Buttons


// Base Typography Settings

$body-font: 'Crimson Text', serif;
$body-color: #404040;
$body-line-height: 1.6;

$regular-sansserif-font: 'AvenirNextLTW01-Regular', sans-serif;
$medium-sansserif-font: "Avenir Next LT W01 Demi", sans-serif;
$light-sansserif-font: 'AvenirNextLTW01-Regular', sans-serif;


// Headings

$heading-font: 'Avenir Next LT W01 Bold', sans-serif;
$heading-line-height: 1.375;

$h1-font-size: rem-calc(48);
$h2-font-size: rem-calc(32);
$h3-font-size: rem-calc(24);
$h4-font-size: rem-calc(20);
$h5-font-size: rem-calc(20);
$h6-font-size: rem-calc(15);
$heading-color: $darkgrey;

$h1-font-reduction: rem-calc(12);
$h2-font-reduction: rem-calc(4);
$h3-font-reduction: rem-calc(2);
$h4-font-reduction: rem-calc(1);
$h5-font-reduction: 0;
$h6-font-reduction: 0;

// Forms

$error-colour: $red;

// Headings

// Inline-elements

// Ordered Lists

// Unordered Lists
