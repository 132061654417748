.full-width-cta {
	padding: rem-calc(40);
	line-height: 1.4;
	margin-bottom: $xl-space;
	text-align: center;
	&.light {
		border: 5px solid $light-grey-4;

	}
	&.dark {
		background-color: $darkgrey;
		border: 5px solid $mediumgrey;
		color: $white;
		.link {
			a {
				text-transform: inherit;
				@extend .removeGenericLink;
			}
		}
	}
	&.blue {
		background-color: $babyblue;
		.indicates-required {
			text-align: left;
			
		}
		.btn,
		.btn-primary,
		input[type=submit] {
			background-color: $darkgrey;
			color: $white;
			border-color: $darkgrey;
			text-transform: none;
			&:hover {
				background-color: $white;
				color: $darkgrey;
			}
		}
	}
	&.hasImage {
		margin-top: $xl-space;
	}
	img {
		margin-top: -100px;
	}
	.title {
		@extend .h2;
		font-family: $heading-font;
		line-height: 1.2
	}
	.short-description {
		margin: 10px 0 20px 0;
		@extend .medium-sansserif;
		p {
			margin-bottom: 0;
		}
	}
	.link {
		a {
			margin-bottom: 0;
		}
	}
}