.story {
  position: relative;
  h3,
  h2 {
    a {
      box-shadow: none;
      color: #292929;
      &:hover {
        text-shadow: 0 1px 0 white;
        background-color: transparent;
        transition: background 0.25s, box-shadow 0.25s, color 0.25s;
        box-shadow: 0 $xs-space 0 $primary-colour-1-hover;
      }
    }
  }
  p.story-location {
    font-size: 0.9em;
    margin: 0 0 15px;
  }
  .teaser {
    p {
      margin-bottom: 0;
    }
    em {
      font-size: inherit !important;
    }
  }
  .sponsored {
    background-color: $darkestgrey;
    color: $white;
    padding: 5px;
    position: absolute;
    bottom: 0;
    @extend .xsmall-sansserif;
    font-family: $heading-font;
    text-transform: uppercase;
  }
  .meta {
    @extend .small-sansserif;
    padding-top: $s-space;
    color: $mediumgrey;
    em {
      font-size: inherit !important;
    }
    .type {
      font-family: $medium-sansserif-font;
      text-transform: uppercase;
      color: $mediumgrey;
    }
    .date,
    .grade-level {
      font-family: $regular-sansserif-font;
    }
    .date {
      font-size: rem-calc(14);
    }
  }
}

.meta-extended {
  @extend .small-sansserif;
  em {
    font-size: inherit !important;
  }
  p {
    margin-bottom: $s-space;
  }
}

.IE {
  .story {
    h2,
    h3 {
      a {
        &:hover {
          box-shadow: none;
          border-bottom: 3px solid $primary-colour-1-hover;
        }
      }
    }
  }
}
