// Mixins

// Prefix

@mixin prefix($property, $value, $vendors: webkit moz ms o) {
      @if $vendors {
        @each $vendor in $vendors {
          #{"-" + $vendor + "-" + $property}: #{$value};
        }
      }
      #{$property}: #{$value};
}

//Font Calculation

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin transform($content) {
  @include prefix(transform, #{$content}, webkit ms o moz);
}

// Media Queries

@mixin lgquery {
  @media only screen and (max-width: map-get($breakpoints, xlarge)) {
    @content;
  }
}

@mixin mdquery {
  @media only screen and (max-width: map-get($breakpoints, large)) {
    @content;
  }
}

@mixin smquery {
  @media only screen and (max-width: map-get($breakpoints, medium)) {
    @content;
  }
}

@mixin mdup {
  @media only screen and (min-width: map-get($breakpoints, large)) {
    @content;
  }
}

// Browser Specific - should not be nested

@mixin ffonly {
  .Gecko, .Gecko50 {
    @content;
  } 
}

// Kentico detects chrome and safari classes as the same

@mixin chromeonly {
  .Chrome, .Chrome53 {
    @content;
  } 
}

@mixin safarionly {
  .Safari53, .Safari {
    @content;
  } 
}

@mixin ieonly {
  .IE, .IE11 {
    @content;
  } 
}


// Language only

@mixin fronly {
  .FRCA {
    @content;
  }
}

@mixin enonly {
  .ENCA {
    @content;
  }
}

// Calculate EMs for specific component

@function em($target, $context: $body-size) {
  @return ($target / $context) * 1em;
}

// SVG with PNG fallback

$image-path: '../img' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x';
@mixin background-image($name, $size:false){
    background-image: url(#{$image-path}/#{$name}.svg);
    @if($size){
        background-size: $size;
    }
    .no-svg &{
        background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

        @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
          background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
        }
    }
}


// Animations and Keyframes

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

// Helpers

@mixin columnCount($args...) {
  @include prefix(column-count, #{$args}, wekit ms o moz);
}
@mixin columnGap($args...) {
  @include prefix(column-gap, #{$args}, wekit ms o moz);
}

@mixin transition($args...) {
  @include prefix(transition, #{$args}, webkit ms o moz);
}

@mixin transition-delay($args...) {
  @include prefix(transition-delay, #{$args}, webkit ms o moz);
}

@mixin appearance($args...) {
  @include prefix(appearance, #{$args}, webkit ms o moz);
}

@mixin animation($args...) {
  @include prefix(animation, #{$args}, webkit ms o moz);   
}

@mixin border-radius($args...) {
  @include prefix(border-radius, #{$args}, webkit ms o moz);
}

@mixin outline($args...) {
  @include prefix(outline, #{$args}, webkit ms o moz);
}
@mixin transform-style($args...) {
  @include prefix(transform-style, #{$args}, webkit ms o moz);
}
@mixin transform($args...) {
  @include prefix(transform, #{$args}, webkit ms o moz);
}

@mixin mask-image($args...) {
  @include prefix(mask-image, #{$args}, webkit ms o moz);
}
@mixin background-size($args...) {
  @include prefix(background-size, #{$args}, webkit ms o moz);
}
@mixin column-break-inside($args...) {
  @include prefix(column-break-inside, #{$args}, webkit ms o moz);
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// Clearfix

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Visually Hidden

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}