.flex-row {
    display: flex;
    justify-content: space-between;
}

// Horizontal alignment
.align-right {
    justify-content: flex-end;
}

// Vertical alignment
.align-middle {
    align-items: center;
}

