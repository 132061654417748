.bubbleology {
  &-submissions {
    margin: $m-space 0;

    h2 {
      font-size: 1.4em;
      margin-bottom: $m-space;
    }
  }
  &-detail {
    border-bottom: 2px solid #ccc;
    margin-bottom: $l-space;
    padding-bottom: 8px;

    p {
      margin-bottom: 0;
    }
    .caption {
      font-weight: 700;
    }
    .location {
      font-size: .8rem;
    }
  }
}