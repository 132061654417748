// Buttons

.button,
input[type="submit"] {
	@extend .small-sansserif;
	border-radius: 5px;
	text-transform: uppercase;
	padding: $s-space $m-space;
	font-family: $heading-font;
	letter-spacing: .5px;
	background-color: $red; 
	color: $white;
	border: 2px solid $red;
	&:hover, &:focus {
		background-color: $white;
		color: $red;
	}
	&.primary {
		background-color: $red;
		color: $white;
		&:hover, &:focus {
			background-color: $white;
			color: $red;
		}
	}
	&.secondary {
		background-color: white;
		color: $darkgrey;
		border: 2px solid $darkgrey;
		text-transform: none;
		&:hover {
			background-color: $darkgrey;
			color: $white;
		}
	}
	&.dark {
		background-color: $darkgrey;
		color: $white;
		border-color: $darkgrey;
		text-transform: none;
		&:hover {
			background-color: $white;
			color: $darkgrey;
		}
	}
	&.callout {
		color: $darkgrey;
		border: 2px solid $darkgrey;
		text-transform: none;
		&:hover {
			background-color: $darkgrey;
			color: $white;
		}
	}
}
a.button {
	box-shadow: none !important;
}

// Header button link

.btn {
	@extend .small-sansserif;
	border-radius: 5px;
	box-shadow: none !important;
	display: inline-block;
	text-transform: uppercase;
	padding: $s-space $m-space;
	font-family: $heading-font;
	letter-spacing: .5px;

	&.fr {
		padding: $s-space 20px;
	}
	
	&-red {		
		background-color: $red; 
		color: $white;
		border: 2px solid $red;
		&:hover, &:focus {
			background-color: $white;
			color: $red;
		}
	}
	&-lightgold {
		background-color: $lightgold;
		color: $darkgrey;
		border: 2px solid $lightgold;
		&:hover, &:focus {
			background-color: $white;
			color: $darkgrey;
		}

	}

	&-blue {
		border: 2px solid $babyblue;
		background-color: $babyblue;
		color: $darkgrey;
		&:hover, &:focus {
			background-color: $white;
			color: $darkgrey;
		}
	}
}