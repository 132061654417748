@import "vars/foundation-settings";
@import "foundation";

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-pagination;
@include foundation-visibility-classes;
@include foundation-float-classes;

// Variables & Settings 

@import "vars/global-vars",
"vars/helpers",
"lib/webfonts",
"lib/mixins",
"lib/jquery.datetimepicker.css",
"icons/sprite";

// Global

@import "global/global",
"global/header/global-header",
"global/footer/global-footer",
"global/buttons",
"global/blockquotes",
"global/colours",
"global/comments";

// Zones

@import "global/body",
"global/rail",
"global/linked-pages";

// Forms
@import "global/forms/cnhs-forms",
"global/forms/checkbox",
"global/forms/radios",
"global/forms/tags",
"global/forms/select",
"global/forms/uploader";

// Layouts 

@import "layouts/landing-page",
"layouts/awards-recipients",
"layouts/content", 
"layouts/kids",
"layouts/flex-containers"; 

// Components
 
@import "components/filters",
"components/showing",
"components/stories",
"components/pagination",
"components/search",
"components/share-icons",
"components/breadcrumbs",
"components/ads",
"components/stories-tile",
"components/stories-list",
"components/story-feature",
"components/taglinks",
"components/image-with-caption",
"components/listicle",
"components/loader",
"components/full-width-cta",
"components/sponsor-information",
"components/gallery",
"components/media-embed",
"components/pdf-list",
"components/message-board",
"components/young-citizens-vote",
"components/bubbleology";

// Ads
@import "global/ads/disruptive-cta";

// Snippets 

@import "snippets/author-with-gg",
"snippets/landing-page-intro";

// Helpers

@import "helpers/cms",
"global/accessibility",
"global/print";
