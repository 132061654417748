.has-margin-left {
    margin-left: rem-calc(60) !important;
}

.align-left {
    text-align: left !important;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}