.checkbox,
.cnhs-form .ktc-checkbox {
	input[type="checkbox"] {
		width: 0;
		height: 0;
		overflow: hidden;
		margin: 0;
		position: absolute;
		left: -9999px;
	}
	label {
		&:before {
			content: '';
			display: inline-block;
			width: 20px;
			height: 20px;
			background-color: #fff;
			margin: 3px 15px 0 -20px;
			vertical-align: top;
			border-radius: 1px;
			border: 1px solid $light-grey-2;
		}
	}
	// Checked state
	input[type="checkbox"]:checked + label {
		color: $red;
		&:before {
			content: '';
			box-shadow: inset 0px 0px 0px 4px $white;
		    background-clip: padding-box;
			background-color: $red;
			border: 1px solid $light-grey-2;
		}
	}
	input[type="checkbox"]:focus + label {
		outline: 1px dotted #bbbbbb;
	}
}
