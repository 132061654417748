// forms added with  CNHS Form Wrapper webpart or widget
// includes classes for Mailchimp form
.cnhs-form,
.message-board-form {
	font-family: $regular-sansserif-font;

	// global error message
	.ErrorLabel {
		color: $error-colour;
		display: block;
		margin: $m-space 0 $l-space;
	}

	.form-group,
	.mc-field-group {
		margin-bottom: rem-calc(40px);
	}

	.full-width-cta & {
		.form-group {
			text-align: left;
		}
	}

	label,
	.editing-form-label {
		font-family: $medium-sansserif-font;
		color: $darkergrey;
		font-size: 0.7rem;
		line-height: 1.8;
		display: block;
		margin-bottom: $xs-space;
		&:first-of-type {
			margin-top: $s-space;
		}
	}

	input[type="text"],
	input[type="email"],
	input[type="checkbox"],
	input[type="radio"]
	input[type="file"],
	select,
	textarea {
		margin-bottom: 0;
		border: 1px solid  #8a8a8a;
		&:focus {
			border: 1px solid  #8a8a8a;
			box-shadow: 0 0 5px $mediumlightgrey;
		}
	}

	// Checkboxes and radio button groups

	.cnhs-form .ktc-checkbox, .checkbox, .cnhs-form .ktc-radio, .radio {
		label {
			margin-bottom: $s-space;
			&:before {
				margin-left: -8px;
			}
		}
		br {
			display: none;
		}
	}

	.checkbox, .radio {
		label {
			&:first-of-type {
				margin-top: 0;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	// mailchimp newsletter groups list
	.mc-field-group.input-group {
		strong {
			font-family: $heading-font;
			line-height: 1.375;
			font-size: 1.2rem;
			font-weight: normal;
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
		li {
			margin-bottom: 0.5em;
			label {
				font-family: $regular-sansserif-font;
			}
		}
	}

	// field error message
	.EditingFormErrorLabel,
	.field-validation-error,
	#mc_embed_signup div.mce_inline_error,
	.form-control-error {
		display: block;
		color: $error-colour;
		font-size: 0.7rem;
		font-family: $medium-sansserif-font;
	}

	// override mailchimp error styles
	#mc_embed_signup div.mce_inline_error {
		background-color: transparent;
		font-weight: normal;
		margin: 0;
		padding: 0;
	}

	.btn {
		transition: all .25s;
	}

	// success message
	.InfoLabel {
		color: $success-color;
	}

	// message board styles
	.message-board-form-leave-message {
		@extend .h3;
		font-family: $heading-font;
		line-height: 1.2;
	}

	// blue cta styles
	.blue & {
		.form-group-submit {
			margin-bottom: 0;
			input[type="submit"] {
				font-size: 0.8rem;
				text-transform: uppercase;
			}
		}
	}

	// recaptcha styles
	.form-group {
		.grecaptcha {
			@media only screen and (max-width: 430px) {
				transform:scale(0.66);
				-webkit-transform:scale(0.66);
				transform-origin:0 0;
				-webkit-transform-origin:0 0;
			}

			@media only screen and (min-width: 1023px) and (max-width: 1200px) {
				transform:scale(0.77);
				-webkit-transform:scale(0.77);
				transform-origin:0 0;
				-webkit-transform-origin:0 0;
			}
		}
	}

	.btn-calendar {
		padding: 15px 30px;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: .5px;
		text-transform: uppercase;
	}

	.btn-calendar-now {
		text-decoration: none;
		background: transparent;
		text-shadow: 0 1px 0 white;
		transition: background 0.25s,
		box-shadow 0.25s,
		color 0.25s;
		color: $darkestgrey;
		box-shadow: 0 3px 0 $primary-colour-1-hover;
		&:hover {
			text-shadow: none;
			background: $primary-colour-1-hover;
			color: $darkestgrey;
		}
	}
}

.formwidget-submit-text {
	color: green;
	font-size: 20px;
	margin-top: 30px;
}
